import { html } from 'htm/preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Ctx, ctx, usePagination } from '.';
import { ConceptGroupsFiler, RangesFilters, Range, Slider } from './filters';
import { Chemical, Datum, Pack, getCurrency } from './model';
import { MarvinJSUtil } from './marvin';
import { currencySymbol } from './cart';
import Skeleton from 'peact-loading-skeleton';
import * as api from './api';
import { route } from 'preact-router';
import { Pagination } from './ui/pagination';
import { PageSize } from './ui/page-size';

export function MarvinJs({ dropdown }: { dropdown: boolean }) {

    const { state, dispatch } = ctx();

    const sketcherRef = useRef(null);
    const [sketcher, setSketcher] = useState(null as any);

    useEffect(() => {
        const sku = new URLSearchParams(window.location.search).get('sku');

        if (!sku && !state.search.molecule.relation) {
            dispatch({ type: 'setMoleculeRelation', relation: 'similar' });
        }
        if (sketcherRef.current) {
            MarvinJSUtil.getEditor(sketcherRef.current).then((sketcherInstance: any) => {
                console.log("SKETCHER", sketcherInstance);
                (window as any).m = sketcherInstance;
                if (state.search.molecule.block) {
                    sketcherInstance.importAsMol(state.search.molecule.block);
                }

                setSketcher(sketcherInstance);
                console.log("setSketcher", sketcherInstance);

                sketcherInstance.on('molchange', () => {
                    console.log("molchange", state.search);
                    dispatch({ type: 'setMoleculeBlock', block: sketcherInstance.exportAsMol() });
                });
                dispatch({ type: 'SKETCHER_INSTANCE', sketcher: sketcherInstance });

                if (sku) {
                    api.getById(sku).then((data) => {
                        dispatch({ type: 'setMolecule', molecule: { block: data.block, relation: '' } });
                        sketcherInstance.importAsMol(data.block);
                        dispatch({ type: 'setMolecule', molecule: { relation: 'similar', block: data.block } });
                    });
                }
            }).catch((error) => { console.log(error) });



        }

    }, [sketcherRef]);


    const searchMolecule = (relation: string) => {
        console.log(sketcherRef.current, sketcher);
        dispatch({ type: 'setMolecule', molecule: { relation, block: sketcher.exportAsMol() } });
    };


    return html`<div>
    <iframe ref=${sketcherRef} id="sketch" src="/marvin.html"
        style="overflow: hidden; min-width: 100%; min-height: 450px; border: 1px solid darkgray;" />
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <!-- /ko -->
        </div>

    </div>
    ${dropdown
            ? html`<div class="sketcher-buttons">
            <select value=${state.search?.molecule?.relation} onchange=${(e: any) => searchMolecule(e.target.value)}>
                <option value="substructure">Sub-Structure</option>
                <option value="similar">Similarity</option>
                <option value="superstructure">Super-Structure</option>
            </select>
            ${state.search?.molecule?.relation == 'similar'
                    ? html`<div style="width:100%">
                <div>
                    <${Slider} value=${state.search.molecule?.distance || 1} from=${0} to=${1}><//>
                </div>
            </div>`
                    : ''
                }
        </div>`
            : html`<div class="sketcher-buttons">
        <button onclick=${() => searchMolecule('substructure')} class="sketcher-button action ${state.search?.molecule?.relation == 'substructure' ? 'primary' : ''}">Sub-Structure</button>
        <button onclick=${() => searchMolecule('similar')} class="sketcher-button action ${state.search?.molecule?.relation == 'similar' ? 'primary' : ''}">Similarity</button>
<!--        <input style="width:50px;margin-top:8px" type="text" value=${state.search?.molecule?.distance || 1} oninput=${(e: any) => dispatch({ type: 'setMoleculeDistance', distance: e.target.value })} />     -->   
        <button onclick=${() => searchMolecule('superstructure')} class="sketcher-button action ${state.search?.molecule?.relation == 'superstructure' ? 'primary' : ''}">Super-Structure</button>
        </div>
        <div class="sketcher-buttons">
        <div style="width:400px; ${state.search?.molecule?.relation == 'similar' ? '' : 'display:none'}">
                    <${Slider} value=${state.search.molecule?.distance || 1} from=${0} to=${1}><//>
                </div>
    </div>`}
</div>`;
}

export function Spinner() {
    return html`<div class="loading">
        <div></div><div></div>
    </div>`;
}


export function Sketcher({ state, dispatch }: Ctx) {

    useEffect(() => {
        dispatch({ type: 'resetFilters' });
        console.log("resetFilters and q");
        dispatch({ type: 'setSearchQuery', query: '' });
        // dispatch({ type: 'setMoleculeBlock', block: '' });
    }, []);

    return html`<main id="maincontent" class="page-main">
    <${MarvinJs} />
    <${SearchResult} state=${state} dispatch=${dispatch} />
</main>`;
}


export function Advanced() {

    const { state, dispatch } = ctx();
    useEffect(() => dispatch({ type: 'debounceSearch' }), []);


    const sketcher = useRef(null);

    return html`<div style="width:100%;display:flex;padding:10px">
    <div style="padding-right:10px">
        <!--<${MarvinJs} dropdown=${true} />-->

        <${Filters} />
    </div>
    <div style="width:100%;">
        <${SearchResult} state=${state} dispatch=${dispatch}/>
    </div>
</div>`;
}

export function Filters() {
    return html`<div style="width:325px">
    <div class="block filter layered-filter-block" id="layered-filter-block" data-collapsible="true">
        <div class="block-title filter-title" data-count="0" role="tablist">
            <button data-role="title" role="tab" aria-selected="false" aria-expanded="false"
                tabindex="0">Filter</button>
        </div>
        <div class="block-content filter-content">            
            <div class="faq__holder">

                <${ConceptGroupsFiler}></${ConceptGroupsFiler}>

                <${RangesFilters}></${RangesFilters}>

            </div>

        </div>
    </div>
</div>`;
}

export function Catalog({ state, dispatch }: Ctx) {


    useEffect(() => {
        dispatch({ type: 'setMolecule', molecule: { relation: '', block: '' } });
    }, [])


    return html`<main id="maincontent" class="page-main">
    <div class="columns">
        <div class="main">

            <div class="catalog-topnav amasty-catalog-topnav">
            </div><input name="form_key" type="hidden" value="6mEzyDW8zQUCm22R" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
                <!-- ko template: getTemplate() -->


                <!-- /ko -->

            </div>




            <div class="search results">
                <${SearchResult} state=${state} dispatch=${dispatch} />
            </div>






            <div data-am-js="js-init">

            </div>
        </div>
        <!--<${Filters} />-->
    </div>
</main>`;
}

export function SearchResult({ state, dispatch }: Ctx) {

    const [clickedRow, setClickedRow] = useState(-1);

    //const [page, pageSize, pages, setPage, setPageSize] = usePagination(state.searchResponse?.total || 0, 1, 12);

    const pageSize = state.searchResponse?.limit || 12;
    const totalPages = Math.ceil((state.searchResponse?.total || 0) / pageSize);
    const page = Math.floor((state.searchResponse?.offset || 0) / pageSize) + 1;
    //const calculatePages = (page:number,totalPages:number) => {
    const pages = [page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4].filter(p => p > 0 && p <= totalPages);
    if (pages.length > 5) pages.length = 5;
    //    return pages;
    //};




    const hideMessage = () => {
        setClickedRow(-1);
    }

    const addToCart = (chemical: Datum, idx: number) => {
        // Temp fix (avoid to add all items (0 as qty))
        setClickedRow(idx);
        return false;
        /*dispatch({
            type: 'add',
            chemical: chemical.molecule,
            options: {},
            packs: state.packs.filter(it => it.code == chemical.molecule.code.replace('F', '')).map(pack => ({ pack, quantity: 0 })),
        });*/
    };

    const addToQuote = (chemical: Datum) => {
        dispatch({
            type: 'quote.add',
            chemical: {...chemical.molecule, unspsc: chemical.molecule.sds.custrecord_fc_unspsc_fccode ?? null},
            quantity: 1,
            options: {},
            packs: [{ quantity: 1, pack: { code: chemical.molecule.code, id: Date.now(), availability: '', availability_reason: '', price: 0, size: '' } }],
        });
    };

    console.log("PAGE", page);


    const setPageSize = (ev: InputEvent) => {
        const size = parseInt((ev.target as any).value);
        dispatch({ type: 'setPageSize', size });
    };

    const changePage = (page: number) => {
        dispatch({ type: 'setPage', page });
    };
    /*
        const toLimitOffset = ([page, pageSize]: any) => {
            return {
                limit: pageSize,
                offset: (page - 1) * pageSize
            }
        };
    */


    useEffect(() => {
        dispatch({ type: 'debounceSearch' });
    }, []);
    return html`<div id="amasty-shopby-product-list">
    <div style="display:flex;justify-content:space-between">
        <div style="display:flex;align-items:center;gap:5px">
            <span class="limiter-text">${state.searchResponse.total} items found</span>
        </div>
        <${Pagination} pages=${pages} page=${page} changePage=${changePage} />
    </div>
    
    ${state.searchResponse.loading ? html`<div style="height: ${state.loadingHeight || 800}px; width: 100%; padding-top: 40px">
        <${Skeleton} count="${(state.loadingHeight || 500) / 20}" lineHeight="20px" />
    </div>` : html`${state.searchResponse.data.length ? html`` : html`<div class="noresultsmessage" style="padding:20px;font-weight:bold">
    Please input a search term or add some filters in the left side bar to start your search</div>`}`}
    <div class="products wrapper grid products-grid" style="${state.searchResponse.data.length ? 'min-height: 600px' : ''}">
        <div class="products list items " style="display:flex;flex-wrap:wrap;">
            ${state.searchResponse.data.map((it, idx) => {
        const packs = state.packs.sort((a, b) => a.price - b.price).filter(pack => pack.code == it.molecule.code);
        return html`<div class="item product product-item">
                ${it.score == 2 ? html`<i class="far fa-check-circle"
                style="position:absolute;color:green;font-size: xx-large;right:40px;top:10px;z-index:20;"></i>` : html``}
                <div class="product-item-info" data-container="product-grid">
                    <a href="/p/${it.molecule.code}" class="product photo product-item-photo" tabindex="-1">

                        <span class="product-image-container" style="width:450px;">
                            <span class="product-image-wrapper" style="padding-bottom: 80%;">
                                <img class="product-image-photo"
                                    src=${api.getMoleculeSvg(it.molecule.code)}
                                    max-width="450" max-height="360" alt="2-Bromoethyl ethyl ether, tech." /></span>
                        </span>
                    </a>
                    <div class="product details product-item-details">
                        <strong class="product name product-item-name">
                            <a class="product-item-link"
                                href="/p/${it.molecule.code}">${it.molecule.code}
                            </a>
                        </strong>

                        <div onclick=${() => route(`/p/${it.molecule.code}`)} class="product-price-list-container">
                            <p class="product-price-list-info-productName">
                                <strong>${it.molecule.name}</strong>
                            </p>
                            <p class="product-price-list-info-cas">
                                <strong>CAS: </strong>${it.molecule.cas}</p>
                            <!--<p class="product-price-list-info-cas">
                                <strong>MDL: </strong>${'it.molecule.mdl'} </p>-->
                            ${state.user || state.location ? (packs.length ? html`<table class="product-price-list">
                            <thead>
                                <tr>
                                    <td>Pack Size</td>
                                    <td class="product-price-list-availability">Availability</td>
                                    <td>Price</td>
                                </tr>
                            </thead>
                            <tbody>
                                ${packs.map(pack => html`
                                <tr>
                                    <td>${pack.size}</td>
                                    <td class="product-price-list-availability">${pack.availability?.replace('&gt;', '>')}</td>
                                    <td>
                                        <div class="price-box price-final_price" data-role="priceBox"
                                            data-product-id="3733" data-price-box="product-id-3733">


                                            <span class="price-container price-final_price tax weee">
                                                <span id="product-price-3733" data-price-amount="17"
                                                    data-price-type="finalPrice" class="price-wrapper "><span
                                                        class="price">${currencySymbol(getCurrency(state))}${pack.price.toFixed(2)}</span></span>
                                            </span>

                                        </div>
                                    </td>
                                    ${state.user ? state.user.catalog_only ? html`` : html`<td>
                                        <${AddPackToCartButton} chemical=${it} pack=${pack} />
                                    </td>` : html``}
                                </tr>`)}
                            </tbody>
                        </table>`: null) : html`Log in to see product detail`

            } 
                        </div>

                        <div class="product-item-inner">
                            <div class="product actions product-item-actions">
                                <div class="actions-primary">
                                    <a class="action view" href="/p/${it.molecule.code}">
                                        <i class="fas fa-microscope"></i>
                                        View </a>

                                    ${state.user && !state.user.catalog_only ? (packs.length > 0 ?
                html`
                                    <div data-role="tocart-form" data-product-sku="001004">
                                        <input type="hidden" name="product" value="3623" />
                                        <input type="hidden" name="uenc"
                                            value="aHR0cDovL21hZ2UyZGV2Mi5kb3VnZGlzY292ZXJ5LmNvbS9jaGVja291dC9jYXJ0L2FkZC91ZW5jL2FIUjBjRG92TDIxaFoyVXlaR1YyTWk1a2IzVm5aR2x6WTI5MlpYSjVMbU52YlM5allYUmhiRzluYzJWaGNtTm9MM0psYzNWc2RDOF9jVDByWlhSb1pYSSUyQy9wcm9kdWN0LzM2MjMv" />
                                        <input name="form_key" type="hidden" value="6mEzyDW8zQUCm22R" />
                                        <!-- ${state.cart.items.find((cartItem, idx) => cartItem.chemical.code == it.molecule.code) ?
                        html`<button disabled class="action tocart primary" style="display:none" >
                                            <span><i class="fas fa-shopping-cart"></i>Added to cart</span></button>`
                        :
                        html`<button onClick=${() => addToCart(it, idx)} type="submit"
                                            title="Add to Cart"
                                            class="action tocart primary">
                                            <span><i class="fas fa-shopping-cart"></i>Add to Cart</span>
                                        </button>`} -->

                        ${html`<button onClick=${() => addToCart(it, idx)} type="submit"
                                        title="Please select the preferred variant"
                                        class="action tocart primary">
                                        <span><i class="fas fa-shopping-cart"></i>Add to Cart</span>
                                    </button>
                                    ${clickedRow === idx ? html`<div onMouseLeave=${() => hideMessage()} style="z-index: 10;background: #e62645; position: absolute; top: -17px; right: -18px; width: 104px; color: #FFF; padding: 10px; font-weight: 500; border-radius: 10px">Please select the preferred variant</div>` : ''}`
                    }
                        
                                    </div>`
                : (state.stockReady ?
                    (state.quote.items.find(cartItem => cartItem.chemical.code == it.molecule.code) ?
                        html`<button disabled class="action tocart primary">
                                                <span><i class="fas fa-file-invoice"></i>Added to quote</span></button>`
                        :

                        (state.user?.punchout_id ? html``: html`<button onClick=${() => addToQuote(it)} type="submit"
                title="Add to Quote"
                class="action tocart primary">
                <span><i class="fas fa-file-invoice"></i>Add to Quote</span>
            </button>`))
                    : html`<button title="loading" class="action tocart primary">
            <${Spinner} />
        </button>`)) : html``}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
    })}
        </div>
    </div>
    <div style="display:flex;justify-content:space-between">
        <${Pagination} pages=${pages} page=${page} changePage=${changePage} />
    </div>

</div>`;
}

function AddPackToCartButton({ chemical, pack }: { chemical: Datum, pack: Pack }) {

    const { state, dispatch } = ctx();

    const addPackToCart = (ev: MouseEvent, chemical: Datum, pack: Pack) => {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        ev.stopPropagation();
        dispatch({
            type: 'add',
            chemical: {...chemical.molecule, unspsc: chemical.molecule.sds.custrecord_fc_unspsc_fccode ?? null},
            options: {},
            packs: state.packs.filter(it => it.id == pack.id).map(pack => ({ pack, quantity: 1 })),
        });
    };

    const alreadyAddedCount = state.cart.items.map(it => it.packs).flat().filter(it => it.pack.id == pack.id).length;

    return html`<button onClick=${(ev: MouseEvent) => addPackToCart(ev, chemical, pack)} type="button"
            title="${alreadyAddedCount ? alreadyAddedCount + ' currently added to cart' : 'Add to Cart'}"
            class="action tocart ${alreadyAddedCount ? 'added-to-cart' : 'primary'}">
            <span><i class="${alreadyAddedCount ? 'fas fa-check' : 'fas fa-shopping-cart'}"></i></span>
        </button>`;
}