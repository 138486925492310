import { html } from 'htm/preact';
import { useState, useEffect } from 'preact/hooks';
import { LanguageSelector, ctx, useInput } from '.';
import { getCurrency, ChemicalDetails, getInventoryById, Molecule, Pack } from './model';

import { OrderFormItem, currencySymbol, netsuiteAddressToAddress } from './cart';
import * as api from './api';
import { Spinner } from './catalog';
import { conceptsMap } from './data';
import AddressList from './address-select';

export interface PackQuantity {
    pack: Pack;
    quantity: number;
}

export function Details({ id }: { id: string }) {


    const [total, setTotal] = useState(0);
    const [chemical, setChemical] = useState(null as Molecule | null);
    const [details, setDetails] = useState(undefined as ChemicalDetails | undefined);
    const [section, setSection] = useState('details');

    const [packs, setPacks] = useState([] as PackQuantity[]);

    const { state, dispatch } = ctx();
    const [inventory, setInventory] = useState([] as Pack[]);

    const [quoteQuantity, setQuoteQuantity] = useInput('');


    useEffect(() => {
        api.getById(id).then(c => {
            setChemical(c);
            //   setPrices(getPrices(c));
        })
        if (state.user || state.location) {
            dispatch({ type: 'stockReady', value: false });

            getInventoryById(id, state.location).then(packs => {
                //setDetails(it);
                setPacks(packs.sort((a, b) => a.price - b.price).map(it => ({ pack: it, quantity: 0 })));
                setInventory(packs);
                dispatch({ type: 'stockReady', value: true });
            });
        }

    }, []);

    const isActive = (s: string) => s == section ? 'active' : '';

    const setQuantity = (size: string, quantity: number) => {
        const packQuantity = packs.find(it => it.pack.size == size);
        if (!packQuantity) {
            return console.error('no pack found for size ' + size);
        }
        packQuantity.quantity = quantity;
        setPacks([...packs]);
        setTotal(packs.reduce((p, n) => p + n.quantity * n.pack.price, 0));

    };

    if (!chemical) {
        return html`<div style="height:100%"></div>`;
    }

    const addPackToCart = (e: MouseEvent, pack: PackQuantity) => {
        e.preventDefault();
        dispatch({
            type: 'add',
            chemical: { ...chemical, unspsc: chemical.sds.custrecord_fc_unspsc_fccode ?? null },
            options: {},
            packs: [pack],
        });
    };






    useEffect(() => {
        if (!state.user) {
            return;
        }
        if (!localStorage.getItem('token')) {
            return;
        }
        api.getAddresses()
            .then(addresses => {
                dispatch({ type: 'SET_ADDRESSES', addresses });
            });
    }, []);




    const data = {
        'properties': [
            ...properties.map(key => {
                const value = (chemical as any)[key];
                if (!value) return null as any;
                return { name: propertiesMap[key], value: key == 'concepts' ? value.map((value: string) => conceptsMap[value] || value).join(', ') : value };
            }).filter(it => it),
        ],
        'hazards': [
            ...hazards.map(key => {
                if (key == 'Precautions' || key == 'Hazards') {
                    return { name: key, value: (chemical as any).data[key].split('|') };
                }
                const value = (chemical as any)[key];
                if (!value) return null as any;
                return { name: propertiesMap[key], value: key == 'concepts' ? value.map((value: string) => conceptsMap[value] || value).join(', ') : value };
            }).filter(it => it),
        ],
        'transport': [
            ...transport.map(key => {
                const value = (chemical as any)[key] || (chemical as any).data[propertiesMap[key]];
                if (!value) return null as any;
                return { name: propertiesMap[key], value: key == 'concepts' ? value.map((value: string) => conceptsMap[value] || value).join(', ') : value };
            }).filter(it => it),
        ],
        'regulatory': Object.keys(chemical.data).filter(it => it.startsWith('Jurisdiction Name_Arr')).map(key => {
            const index = parseInt(key.split('Jurisdiction Name_Arr[')[1]);
            return { name: chemical.data[key], title: chemical.data[`Legislation Name_Arr[${index}]`], value: chemical.data[`Additional Information_Arr[${index}]`] };
        })
            .filter(it => it.name).sort((a, b) => a.name.localeCompare(b.name)),
        'all': [
            ...Object.keys(chemical.data)
                .filter(key => chemical.data[key])
                .map((name: string) => ({ name, value: chemical.data[name] })),
        ],
        'other': [
            ...main.map(key => {
                const value = (chemical as any)[key] || (chemical as any).data[propertiesMap[key]];
                if (!value) return null as any;
                if (key == 'purity') {
                    return { name: 'Purity', value: chemical.data.Purity };
                }

                return { name: propertiesMap[key], value: key == 'concepts' ? value.map((value: string) => conceptsMap[value] || value).join(', ') : value };
            }).filter(it => it),
        ],
    };

    console.log("DATA", data);

    return html`<main id="maincontent" class="page-main">
    <div class="columns">
        <div class="column main">
            <div class="product-info-top">
                <div class="page-title-wrapper product">
                    <h1 class="page-title">
                        <span class="base" data-ui-id="page-title-wrapper" itemprop="name">${chemical.name}</span> </h1>
                </div>
                <div class="product media">
                    <div id="gallery-prev-area"></div>
                    <div class="action-skip-wrapper"><a class="action skip gallery-next-area" href="#gallery-next-area">
                            <span>
                                Skip to the end of the images gallery </span>
                        </a>
                    </div>

                    <div class="gallery-placeholder" data-gallery-role="gallery-placeholder">
                        <!--
    /**
     * Copyright © Magento, Inc. All rights reserved.
     * See COPYING.txt for license details.
     */
    -->
                        <div class="fotorama--hidden"></div>
                        <style></style>
                        <div class="fotorama-item fotorama fotorama1662242159504" data-gallery-role="gallery">
                            <div data-gallery-role="fotorama__focusable-start" tabindex="-1"></div>
                            <div class="fotorama__wrap fotorama__wrap--css3 fotorama__wrap--slide fotorama__wrap--toggle-arrows fotorama__wrap--no-controls"
                                style="min-width: 0px; max-width: 100%;">
                                <div class="fotorama__stage" data-fotorama-stage="fotorama__stage"
                                    style="width: 500px; height: 400px;background-image: url('${api.getMoleculeSvg(chemical.code)}'); background-position: center; background-size: contain; background-repeat: no-repeat ">

                                </div>
                                <div class="fotorama__nav-wrap" data-gallery-role="nav-wrap" style="display: none;">
                                    <div class="fotorama__nav" style="height: 364px;">

                                        <div class="fotorama__thumb__arr fotorama__thumb__arr--left fotorama__arr--disabled"
                                            role="button" aria-label="Previous" data-gallery-role="arrow" tabindex="-1"
                                            disabled="disabled">
                                            <div class="fotorama__thumb--icon"></div>
                                        </div>
                                        <div class="fotorama__nav__shaft">
                                            <div class="fotorama__thumb-border"></div>
                                        </div>
                                        <div class="fotorama__thumb__arr fotorama__thumb__arr--right fotorama__arr--disabled"
                                            role="button" aria-label="Next" data-gallery-role="arrow" tabindex="-1"
                                            disabled="disabled">
                                            <div class="fotorama__thumb--icon"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div data-gallery-role="fotorama__focusable-end" tabindex="-1"></div>
                        </div>
                        <div class="magnifier-preview magnify-hidden" data-gallery-role="magnifier" id="preview"
                            style="display: none;"></div>
                    </div>

                    <div class="action-skip-wrapper">
                        <a class="action skip gallery-prev-area" href="#gallery-prev-area">
                            <span>
                                Skip to the beginning of the images gallery
                            </span>
                        </a>
                    </div>
                    <div id="gallery-next-area"></div>
                </div>
                ${state.user || state.location ? html`<div class="product-info-main">
                    <div class="product-reviews-summary empty">
                        <div class="reviews-actions">
                            <a class="action add" href="/p/${chemical.code}">
                                Be the first to review this product </a>
                        </div>
                    </div>
                    ${total > 0 ? html`<div class="product-price">
                        <span class="total">Total</span><br />
                        <span class="price price-js">${currencySymbol(getCurrency(state))}${total.toFixed(2)}</span>
                    </div>`: html``}
                    <div class="product-add-form">
                        <form>
                            <input type="hidden" name="product" value="669638" />
                            <input type="hidden" name="selected_configurable_option" value="" />
                            <input type="hidden" name="related_product" id="related-products-field" value="" />
                            <input type="hidden" name="item" value="669638" />
                            <input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
                            <div class="product-options-wrapper" id="product-options-wrapper">
                                <div class="fieldset" tabindex="0">
                                    <table class="configurable-table"
                                        style="display: ${inventory.length ? 'block' : 'none'}">
                                        <thead>
                                            <tr>
                                                <th>Pack Size</th>
                                                <th>Price</th>
                                                <th>Availability</th>
                                                ${state.user ? html`<th class="configurable-table-qty">Quantity</th>` : html``}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            ${packs.map(it => html`<tr class="table-row">
                                                <td class="table-size-js">${it.pack.size}</td>
                                                <td class="table-price-js">
                                                    <div class="price-box price-final_price" data-role="priceBox"
                                                        data-product-id="1341315" data-price-box="product-id-1341315">

                                                        <span class="price-container price-final_price tax weee">
                                                            <span id="product-price-1341315" data-price-amount="10"
                                                                data-price-type="finalPrice"
                                                                class="price-wrapper "><span
                                                                    class="price">${currencySymbol(getCurrency(state))}${it.pack.price.toFixed(2)}</span></span>
                                                        </span>

                                                    </div>
                                                </td>
                                                <td ondoubleclick=${(ev: MouseEvent) => ev.ctrlKey && ev.altKey && alert(it.pack.availability_reason)} class="table-availability-js">${it.pack.availability?.replace('&gt;', '>')}</td>
                                                ${state.user ? html`<td class="configurable-table-qty">
                                                    <div class="number-input">
                                                        <div onClick=${() => setQuantity(it.pack.size, Math.max(0, it.quantity - 1))} role="button"
                                                            class="number-input-prepend">
                                                            <span>-</span>
                                                        </div>
                                                        <input onInput=${(e: InputEvent) => setQuantity(it.pack.size, parseFloat((e.target as HTMLInputElement).value))} type="number"
                                                        name="option_qtys[010522-1g]" class="number-input-field"
                                                        value=${it.quantity}/>
                                                        <div onClick=${() => setQuantity(it.pack.size, it.quantity + 1)}
                                                            role="button" class="number-input-append">
                                                            <span>+</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="${state.user?.catalog_only ? 'display:none' : ''}">
                                                    <button onClick=${(ev: MouseEvent) => addPackToCart(ev, it)}
                                                        title="Add to Cart" class="action primary tocart ${it.quantity ? '' : 'disabled'}"
                                                        id="product-addtocart-button">Add to
                                                        Cart</button>
                                                </td>` : html``}
                                            </tr>`)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            ${!state.user || state.user?.catalog_only ? html`` : html`<div class="product-options-bottom ${state.stockReady ? '' : 'stockloading'}">

                                <${QuickQuoteForm} chemical=${chemical} packs=${packs} quoteQuantity=${quoteQuantity} setQuoteQuantity=${setQuoteQuantity} />
                                
                                            <${AddToCartButton} chemical=${chemical} packs=${packs} />
                                            <${AddToQuoteButton} chemical=${chemical} size=${quoteQuantity} packs=${packs} />
                                            <${ToggleQuickQuoteButton} />

                            </div>`}

                        </form>
                    </div>
                    <div class="product-social-links">
                        <div class="product-addto-links" data-role="add-to-links">

                            <a href="#" data-role="add-to-links" class="action tocompare"><span>Add to
                                    Compare</span></a>

                        </div>
                    </div>
                </div>` : html`<div class="product-info-main">
                    <div>SELECT YOUR REGION BELOW or log in to see your specific pricing and stock</div>
                    <div><${LanguageSelector}/></div>
                    
                </div>`}
            </div>
            <div class="product info detailed">
                <div class="product data items" role="tablist">
                    <div class="data item title active" data-role="collapsible" id="tab-label-additional" role="tab"
                        data-collapsible="true" aria-controls="additional" aria-selected="false" aria-expanded="true"
                        tabindex="0">
                        <a class="data switch" tabindex="-1" data-toggle="trigger" href="#additional"
                            id="tab-label-additional-title">
                            More Information </a>
                    </div>
                    <div class="data item content" aria-labelledby="tab-label-additional" id="additional"
                        data-role="content" role="tabpanel" aria-hidden="false">
                        <div class="additional-attributes-wrapper table-wrapper">
                            <table class="data table additional-attributes" id="product-attribute-specs-table">
                                <caption class="table-caption">More Information</caption>
                                <tbody>
                                    <tr>
                                        <th class="col label" scope="row">CAS</th>
                                        <td class="col data" data-th="CAS">${chemical.cas}</td>
                                    </tr>
                                    <tr>
                                        <th class="col label" scope="row">Purity</th>
                                        <td class="col data" data-th="Purity">${details ? details.purity * 100 : ''}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="data item title" data-role="collapsible" id="tab-label-reviews" role="tab"
                        data-collapsible="true" aria-controls="reviews" aria-selected="false" aria-expanded="false"
                        tabindex="0">
                        <a class="data switch" tabindex="-1" data-toggle="trigger" href="#reviews"
                            id="tab-label-reviews-title">
                            Reviews </a>
                    </div>
                    <div class="data item content" aria-labelledby="tab-label-reviews" id="reviews" data-role="content"
                        role="tabpanel" aria-hidden="true" style="display: none;">
                        <div id="product-review-container" data-role="product-review"></div>
                        <div class="block review-add">
                            <div class="block-title"><strong>Write Your Own Review</strong></div>
                            <div class="block-content">
                                <form class="review-form" method="post" id="review-form" data-role="product-review-form"
                                    data-bind="scope: 'review-form'" novalidate="novalidate">
                                    <input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
                                    <fieldset class="fieldset review-fieldset" data-hasrequired="* Required Fields">
                                        <legend class="legend review-legend"><span>You're
                                                reviewing:</span><strong>${chemical.name}</strong></legend><br />
                                        <div class="field review-field-nickname required">
                                            <label for="nickname_field" class="label"><span>Nickname</span></label>
                                            <div class="control">
                                                <input type="text" name="nickname" id="nickname_field"
                                                    class="input-text" data-validate="{required:true}"
                                                    data-bind="value: nickname()" aria-required="true" />
                                            </div>
                                        </div>
                                        <div class="field review-field-summary required">
                                            <label for="summary_field" class="label"><span>Summary</span></label>
                                            <div class="control">
                                                <input type="text" name="title" id="summary_field" class="input-text"
                                                    data-validate="{required:true}" data-bind="value: review().title"
                                                    aria-required="true" />
                                            </div>
                                        </div>
                                        <div class="field review-field-text required">
                                            <label for="review_field" class="label"><span>Review</span></label>
                                            <div class="control">
                                                <textarea name="detail" id="review_field" cols="5" rows="3"
                                                    data-validate="{required:true}" data-bind="value: review().detail"
                                                    aria-required="true"></textarea>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div class="actions-toolbar review-form-actions">
                                        <div class="primary actions-primary">
                                            <button type="submit" class="action submit primary"><span>Submit
                                                    Review</span></button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">

                <!-- ko template: getTemplate() -->

                <!-- /ko -->

            </div>

            <div class="product-tabs">
                <ul class="product-links">
                    <li class="${isActive('details')}"><a onclick="${() => setSection('details')}">Details</a></li>
                    ${data.properties.length ? html`<li class="${isActive('properties')}"><a
                            onclick="${() => setSection('properties')}">Properties</a></li>` : html``}
                    ${data.hazards.length ? html`<li class="${isActive('hazards')}"><a
                            onclick="${() => setSection('hazards')}">Hazards</a></li>` : html``}
                    ${data.transport.length ? html`<li class="${isActive('transport')}"><a
                            onclick="${() => setSection('transport')}">Transport</a></li>` : html``}
                    ${data.regulatory.length ? html`<li class="${isActive('regulatory')}"><a
                            onclick="${() => setSection('regulatory')}">Regulatory</a></li>` : html``}
                </ul>

                <div class="product-contenst">
                    ${productDetailsSection(data, section, chemical, details)}

                </div>
            </div>

        </div>
    </div>
</main>`;
}

function ToggleQuickQuoteButton() {
    const { state, dispatch } = ctx();

    // if in punchout session do not show toggle quick quote button
    if (state.user?.punchout_id) return html``;

    return html`<a onClick=${() => dispatch({ type: 'quoteQuote.open', value: !state.quickQuoteOpen })} title="Add to Quote"
        class="action button-goto toquote" id="product-addtocart-button">
        <span>Quick Quote</span>
    </a>`;
}

function AddToCartButton({ chemical, packs }: { chemical: Molecule, packs: PackQuantity[] }) {

    const { state, dispatch } = ctx();

    if (!state.stockReady || !packs.length) {
        return html``;
    }

    const addToCart = (e: MouseEvent) => {
        e.preventDefault();
        dispatch({ type: 'add', chemical: { ...chemical, unspsc: chemical.sds.custrecord_fc_unspsc_fccode ?? null }, options: {}, packs });
    };

    const alreadyExistsInCart = state.cart.items.find(it => it.chemical.code == chemical.code);

    if (alreadyExistsInCart) {
        return html`<div class="box-tocart">
        <div class="fieldset">
            <div class="actions"><button disabled
                    class="action primary tocart" id="product-addtocart-button">
                    <span>Added</span>
                </button>
                </div></div></div>`;
    } else {
        return html`<div class="box-tocart">
        <div class="fieldset">
            <div class="actions"><button onClick=${addToCart} title="Add to Cart"
                class="action primary tocart" id="product-addtocart-button">
                <span>Add to Cart</span>
            </button>
            </div></div></div>`;
    }
}

function AddToQuoteButton({ chemical, size, packs }: { chemical: Molecule, size: string, packs: PackQuantity[] }) {

    const { state, dispatch } = ctx();

    const addToQuote = (e: MouseEvent) => {
        e.preventDefault();
        dispatch({
            type: 'quoteQuote.open', value: false,
        });

        if (packs.length > 1) {
            packs = packs.filter(it => it.quantity > 0).length ? packs.filter(it => it.quantity > 0) : packs.slice(0, 1);
        }

        dispatch({
            type: 'quote.add',
            chemical: { ...chemical, unspsc: chemical.sds.custrecord_fc_unspsc_fccode ?? null },
            quantity: 1, options: {}, packs: packs.length ? packs.map(it => {
                it.pack.size = it.quantity > 1 ? it.quantity + ' x ' + it.pack.size : it.pack.size;
                it.quantity = 1;
                return it;
            }) : [{ quantity: 1, pack: { code: chemical.code, id: Date.now(), availability: '', availability_reason: '', price: 0, size } }]
        });
    };

    // if in punchout session do not show add to quote button
    if (state.user?.punchout_id || state.user?.catalog_only) return html``;

    return state.quote.items.find(it => it.chemical.code == chemical.code)
        ? html`<div class="box-tocart">
        <div class="fieldset">
            <div class="actions">
            <button
        disabled class="action primary tocart toquote"
        id="product-addtocart-button">
        <span>Added</span>
    </button>
    </div></div></div>`
        : html`<div class="box-tocart">
        <div class="fieldset">
            <div class="actions">
    <button onClick=${addToQuote} title="Add to Quote"
        class="action primary tocart toquote" id="product-addtocart-button">
        <span>Add to Quote</span>
    </button></div></div></div>`;
}

function ViewCartButton({ packs }: { packs: PackQuantity[] }) {

    const { state } = ctx();

    if (!state.stockReady || !packs.length || state.user?.catalog_only) return html``;

    return html`<a href="/cart" class="action button-goto">
                        <span class="text">View Cart</span>
                    </a>`;
}

function ViewQuoteButton({ packs }: { packs: PackQuantity[] }) {

    const { state } = ctx();

    if (!state.stockReady || !packs.length) return html``;

    if (state.user?.punchout_id || state.user?.catalog_only) return html``;

    return html`<a href="/quote" class="action button-goto toquote">
                        <span class="text">View Quote</span>
                    </a>`;
}

function QuickQuoteForm({ chemical, packs, quoteQuantity, setQuoteQuantity }: { chemical: Molecule, packs: PackQuantity[], quoteQuantity: string, setQuoteQuantity: (value: string) => void }) {

    const { state, dispatch } = ctx();

    const [billingAddress, setBillingAddress] = useState(undefined);
    const [shippingAddress, setShippingAddress] = useState(undefined);
    const [comments, setComments] = useInput('');
    const [placing, setPlacing] = useState(false);

    const getSelectedBillingAddress = () => billingAddress ?? state.user?.default_billing;
    const getSelectedShippingAddress = () => shippingAddress ?? state.user?.default_shipping;


    const createQuickQuote = (e: MouseEvent) => {

        e.preventDefault();
        if (placing) {
            return;
        }
        if (!quoteQuantity.trim()) {
            return alert('Please fill in quantity first');
        }

        const billingAddress = getSelectedBillingAddress();
        const shippingAddress = getSelectedShippingAddress();

        if (!billingAddress || !shippingAddress) {
            alert('Please select a billing and shipping address');
            return;
        }

        const items: OrderFormItem[] = [{
            sku: chemical.code + '-' + quoteQuantity.replace(/\s/g, '').toUpperCase(),
            name: chemical.name,
            price: 0,
            quantity: 1,
            id: Date.now(),
            tax: 0,
        }];


        const billing = netsuiteAddressToAddress(state.addresses.find(it => it.id == billingAddress) as any);
        const shipping = netsuiteAddressToAddress(state.addresses.find(it => it.id == shippingAddress) as any);

        setPlacing(true);


        api.placeQuote({
            items,
            currency: state.user?.currency || 'GBP',
            billing,
            shipping,
            po: '',
            comments,
        })
            .then(() => {
                alert('Thank you for your quote.  Our team will be processing it shortly.');
            })
            .finally(() => {
                setPlacing(false);
                dispatch({ type: 'quoteQuote.open', value: false });
            });
    };

    if (!state.quickQuoteOpen || !state.stockReady || !packs.length || state.user?.catalog_only) return html``;


    return html`<fieldset class="fieldset" style="width:100%">
    <div class="field">
        <label class="label">Quantity*</label>
        <input onInput=${setQuoteQuantity} value=${quoteQuantity} name="quantity" type="text" class="control" required/>
    </div>
    <div class="field">
        <label class="label">Comment</label>
        <textarea name="quantity" type="text" class="control"></textarea>
        <form id="checkout-doug">
        <label for="fname">Shipping Address</label>
        <${AddressList} value=${getSelectedShippingAddress()} onchange=${setShippingAddress}/>
        <label for="lname">Billing Address</label>
        <${AddressList} value=${getSelectedBillingAddress()} onchange=${setBillingAddress}/>
    </form>
    </div>
    <button onClick=${createQuickQuote} title="Create Quick Quote"
    class="action primary tocart toquote" id="product-addtocart-button">
    <span>Create Quick Quote</span>
</button>
</fieldset>`;
}



function product_tab(list: { name: string; title?: string; value?: string; }[]) {

    const pictograms = list.filter(it => it.name.startsWith('Pictogram')).map(it => it.value).filter(it => it).map(it => it?.toLowerCase());

    if (pictograms.length) {
        list = list.filter(it => !it.name.startsWith('Pictogram'));
    }

    return html`<div>
    <table class="product-content-table">
        <tbody>
            ${pictograms.length ? html`<tr>
                <th>Pictograms</th>
                <td>${pictograms.map(it => html`<img class="pictogram" src="/assets/images/${it}.png"/>`)} </td>
            </tr>` : html``}
            ${list.filter(it => it.value).map(({ name, title, value }) => html`<tr>
                <th>${name}</th>
                <td>${title ? html`<b>${title || ''}</b><br/>` : html``}${Array.isArray(value) ? value.map(val => html`${val}<br/>`) : value}</td>
            </tr>`)}
        </tbody>
    </table>
</div>`;
}

function downloadSDS(url: string) {
    return "https://7128445.app.netsuite.com" + url;
}
const propertiesMap: { [key: string]: string } = {
    adr_packing_group: "ADR Packing Group",
    adr_transport_class: "ADR Transport Class",
    adr_transport_subclass: "ADR Transport Subclass",
    adr_un_number: "ADR UN Number",
    appearance: "Appearance",
    asymmetric_atoms: "Asymmetric atoms",
    auto_ignition_temperature: "Auto Ignition Temperature",
    boiling_point: "Boiling Point",
    cas: "CAS",
    concepts: "Concept Codes",
    decomposition_temperature: "Decomposition Temperature",
    density: "Density",
    explosive_properties: "Explosive Properties",
    flammability: "Flammability",
    flash_point: "Flash Point",
    h_codes: "H Codes",
    h_phrases: "H Phrases",
    h_bond_acceptors: "H bond acceptors",
    h_bond_donors: "H bond donors",
    iata_packing_group: "IATA Packing Group",
    iata_transport_class: "IATA Transport Class",
    iata_transport_subclass: "IATA Transport Subclass",
    iata_un_number: "IATA UN Number",
    imdg_packing_group: "IMDG Packing Group",
    imdg_transport_class: "IMDG Transport Class",
    imdg_transport_subclass: "IMDG Transport Subclass",
    imdg_un_number: "IMDG UN Number",

    packing_group: "Packing Group",
    transport_class: "Transport Class",
    transport_subclass: "Transport Subclass",
    un_number: "UN Number",

    inchi: "INCHI Code",
    iupac_name: "IUPAC Name",
    log_p: "LogP",
    melting_point: "Melting Point",
    molecular_weight: "Molecular weight",
    odour: "Odour",

    other_information: "Other Information",
    oxidising_properties: "Oxidising Properties",
    p_codes: "P Codes",
    p_phrases: "P Phrases",
    partition_coefficient: "Partition Coefficient",
    physical_state: "Physical State",
    pictogram_1: "Pictogram 1",
    pictogram_2: "Pictogram 2",
    pictogram_3: "Pictogram 3",
    pictogram_4: "Pictogram 4",
    pictogram_5: "Pictogram 5",
    product_name: "Product Name",
    other_names: 'Other Names',
    product_code: "Product Code",
    proper_shipping_name: "Proper Shipping Name",
    purity: "Purity",
    smiles: "SMILES",
    signal_word: "Signal Word",
    solubility: "Solubility",
    vapour_density: "Vapour Density",
    vapour_pressure: "Vapour Pressure",

    viscosity: "Viscosity",
    block: "block",
    fsp3: "fsp3",
    inchikey: "inchikey",
    ph: "pH",

};

/*
Hazard Information:
Signal Word
P codes
P phrases
H codes
H phrases
Pictogram 1
Pictogram 2
Pictogram 3
Pictogram 4
Pictogram 5
*/

const hazards = [
    'signal_word',
    'Hazards',
    'Precautions',
    'pictogram_1', 'pictogram_2', 'pictogram_3', 'pictogram_4', 'pictogram_5'
];

/*
Transport Information:
IMDG UN Number
ADR UN Number
IATA UN Number
IMDG Packing group
ADR Packing Group
IATA Packing Group
IMDG Transport Class
ADR Transport Class
IATA Transport Class
IMDG Transport Subclass
ADR Transport Subclass
IATA Transport Subclass
Proper Shipping Name
*/

const transport = [
    'un_number', 'transport_class', 'transport_subclass', 'packing_group',
    'imdg_un_number', 'adr_un_number', 'iata_un_number',
    'imdg_packing_group', 'adr_packing_group', 'iata_packing_group',
    'proper_shipping_name',
    'imdg_transport_class', 'adr_transport_class', 'iata_transport_class',
    'imdg_transport_subclass', 'adr_transport_subclass', 'iata_transport_subclass',
];


/*
Properties Section:
Physical State
Appearance
Density
Melting Point
Flash Point
Boiling Point
Odour
Solubilty
Vapour Pressure
Partition Coefficient 
Viscosity
pH
Flammability
Vapour Density
Autoignition Temperature
Decomposition Temperature
Explosive Properties
Oxidising Properties 
Other Information
*/

const properties = ['physical_state', 'appearance', 'density', 'melting_point', 'flash_point', 'boiling_point', 'odour', 'solubility', 'vapour_pressure', 'partition_coefficient',
    'viscosity', 'ph', 'flammability', 'vapour_density', 'auto_ignition_temperature', 'decomposition_temperature', 'explosive_properties', 'oxidising_properties', 'other_information'];

/*
Column headings are: 
Structure
Product Code
Product Name
CAS
Purity
IUAPAC Name
SMILES
INCHI Code
INCHI Key
Asymmetric Atoms
LogP
H bond Acceptors
H Bond Donors
Molecular Weight
Fsp3
Concept Codes*/

const main = ['product_code', 'structure', 'product_name', 'other_names', 'cas', 'purity', 'molecular_weight', 'iupac_name', 'smiles', 'inchi', 'inchikey', 'asymmetric_atoms', 'log_p', 'h_bond_acceptors', 'h_bond_donors', 'fsp3', 'concepts'];

function ValidateEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    alert("You have entered an invalid email address!")
    return (false)
}

function productDetailsSection(data: any, current: string, chemical: Molecule, details?: ChemicalDetails) {

    const [email, setEmail] = useInput('');

    const onRequestClick = () => {
        if (ValidateEmail(email)) {
            api.requestSDS(chemical.code, email)
                .then(() => alert('SDS requested, we will contact you shortly'))
                .catch(() => alert('Error requesting SDS'));
        }
    };

    if (current === 'properties') {
        return product_tab(data.properties);
    } else if (current === 'hazards') {
        return [
            html`<table class="product-content-table"><tbody><tr><th>SDS</th><td style="display:flex;flex-direction:column">${[
                chemical?.sds.custrecord_sdslink_en ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_en as any)}>Download SDS (EN)</a>` : '',
                chemical?.sds.custrecord_sdslink_de ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_de as any)}>Download SDS (DE)</a>` : '',
                chemical?.sds.custrecord_sdslink_fr ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_fr as any)}>Download SDS (FR)</a>` : '',
                chemical?.sds.custrecord_sdslink_it ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_it as any)}>Download SDS (IT)</a>` : '',
                chemical?.sds.custrecord_sdslink_es ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_es as any)}>Download SDS (ES)</a>` : '',
                chemical?.sds.custrecord_sdslink_dk ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_dk as any)}>Download SDS (DK)</a>` : '',
                chemical?.sds.custrecord_sdslink_nl ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_nl as any)}>Download SDS (NL)</a>` : '',
                chemical?.sds.custrecord_sdslink_sv ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_sv as any)}>Download SDS (SV)</a>` : '',
                chemical?.sds.custrecord_sdslink_fi ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_fi as any)}>Download SDS (FI)</a>` : '',
                chemical?.sds.custrecord_sdslink_no ? html`<a target="_blank" href=${downloadSDS(chemical?.sds.custrecord_sdslink_no as any)}>Download SDS (NO)</a>` : '',
                Object.keys(chemical?.sds || {}).find(key => (chemical?.sds as any || {})[key]) ? html`` : html`<div>
                Please input your email to request SDS file
                <br/>
                <input value=${email} oninput=${setEmail} type="email"/>
                <button onclick=${onRequestClick}>Request SDS</button>    
            </div>`]}</td></tr></tbody></table>`,
            product_tab(data.hazards)
        ];
    } else if (current === 'transport') {
        return product_tab(data.transport);
    } else if (current === 'regulatory') {
        return product_tab(data.regulatory);
    } else if (current === 'all') {
        return product_tab(data.all);
    } else {
        return product_tab(data.other);
    }

}