import { html } from "htm/preact";
import { ctx } from ".";
import { route } from "preact-router";
declare var require: any;


export function Landing() {
    const { dispatch } = ctx();

    const setLocation = (location: string) => {
        dispatch({ type: 'location.set', location });
        route('/');
    };

    return html`<main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="columns">
        <div class="column main">
            <div class="homepage-container">
                <div class="homepage-logo-container">
                    <img src="${require('/assets/images/d.png')}" alt="Doug Discovery" class="homepage-logo" />
                </div>
                <div class="landing-main">
                <div class="block-title"><h2 id="block-customer-login-heading">Where are you visiting from? </h2></div>
                <div class="homepage-search-buttons landing-buttons">
                    <a onclick=${() => setLocation('GB')} href="/" class="home-search-button-primary large_region">United Kingdom</a>
                    <a onclick=${() => setLocation('EU')} href="/" class="home-search-button-primary large_region">Europe</a>
                    <a onclick=${() => setLocation('ROW')} href="/" class="home-search-button-primary large_region">Rest of the World</a>
                </div>
                <div class="field note" style="margin-top:10px;">If you have an account, log in for specific account pricing</div>
                </div>            
            </div>
        </div>
    </div>
</main>`;
}