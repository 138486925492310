import { html } from 'htm/preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Address, ctx, useInput } from '.';
import { PackQuantity } from './details';
import { CartItem, cartsearch, Chemical, getCurrency, getInventory } from './model';
import * as api from './api';
import { route } from 'preact-router';
import { Spinner } from './catalog';

import * as commerce from './commerce';
import AddressList from './address-select';

class Punchout {

    _block: any;

    async submitCart(cart: { items: CartItem[], currency: string }) {
        try {
            this.showBlock();
            const res = await api.punchoutCart(cart);
            if (!res.url || !res.data) {
                alert(res.message || 'An error happened with punchout');
                this.hideBlock();
                return;
            }
            const form = this.createPunchoutForm(res);
            localStorage.clear();

            form.submit();
            form.onerror = () => this.hideBlock();
            form.onsubmit = () => localStorage.removeItem('punchoutSessionId');
        } catch (error) {
            console.log("ERROR", error);
            alert((error as any).message);
            this.hideBlock();
        }
    }


    createPunchoutForm({ data, url }: { url: string, data: { [key: string]: string } }) {
        const form = document.createElement('form');
        form.setAttribute('hidden', 'true');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', url);
        Object.keys(data).forEach(key => {
            const input1 = document.createElement('input');
            input1.setAttribute('type', 'text');
            input1.setAttribute('name', key);
            input1.setAttribute('value', data[key]);
            form.appendChild(input1);
        });
        document.body.appendChild(form);
        return form;
    }


    showBlock() {
        this._block = this._block || this.createBlock();
        document.body.appendChild(this._block);
    }

    hideBlock() {
        this._block = this._block || this.createBlock();
        document.body.removeChild(this._block);
    }

    createBlock() {
        const block = document.createElement('div');
        block.classList.add('punchout-block');
        const loading = document.createElement('div');
        loading.classList.add('punchout-loading');
        loading.appendChild(document.createElement('div'));
        loading.appendChild(document.createElement('div'));
        block.appendChild(loading);
        return block;
    }

}


const punchout = new Punchout();


export function currencySymbol(currency: string = 'GBP') {
    switch (currency) {
        case 'GBP':
            return '£';
        case 'USD':
            return '$';
        case 'EUR':
            return '€';
        case 'JPY':
            return '¥';
        case 'CNY':
            return '¥';
        case 'AUD':
            return '$';
        case 'CAD':
            return '$';
        case 'CHF':
            return 'CHF';
        case 'SEK':
            return 'kr';
        case 'NOK':
            return 'kr';
        case 'DKK':
            return 'kr';
        case 'NZD':
            return '$';
        case 'MXN':
            return '$';
        case 'SGD':
            return '$';
        case 'HKD':
            return '$';
        case 'ZAR':
            return 'R';
        case 'TRY':
            return '₺';
        case 'RUB':
            return '₽';
        case 'INR':
            return '₹';
        case 'BRL':
            return 'R$';
        case 'TWD':
            return 'NT$';
        case 'THB':
            return '฿';
        case 'MYR':
            return 'RM';
        case 'PHP':
            return '₱';
        case 'IDR':
            return 'Rp';
        case 'KRW':
            return '₩';
        case 'VND':
            return '₫';
        case 'CZK':
            return 'Kč';
        case 'PLN':
            return 'zł';
        case 'HUF':
            return 'Ft';
        case 'ILS':
            return '₪';
        default:
            return currency;
    }
}
/*    pub id: Option<String>,
pub name: Option<String>,
pub email: Option<String>,
#[serde(deserialize_with = "de_deliver_to")]
pub deliver_to: Vec<String>,
pub street: Vec<String>,
pub city: String,
pub state: String,
pub postal_code: String,
pub country: String,
pub phone: Option<String>,
pub fullname: Option<String>,
pub company: Option<String>,
 
*/
export interface InstaAddress {
    id?: string;
    name?: string;
    email?: string;
    deliver_to: string[];
    street: string[];
    city: string;
    state: string;
    postal_code: string;
    country: string;
    phone?: string;
    fullname?: string;
    company?: string;

}

export interface OrderFormItem {
    id: number;
    name: string;
    sku: string;
    quantity: number;
    price: number;
    tax?: number;
}


export function netsuiteAddressToAddress(address: api.NetsuiteAddress): InstaAddress {
    return {
        id: address.id.toString(),
        deliver_to: [address.attention, address.addressee].filter(it => it),
        street: [address.addr1, address.addr2].filter(it => it),
        city: address.city,
        state: address.state || '',
        postal_code: address.zip,
        country: address.country,
        phone: address.addrphone,
    };

}

export function CartView() {
    return CartOrQuoteView('cart');
}

export function QuoteView() {
    return CartOrQuoteView('quote');
}

function CartOrQuoteView(kind: 'cart' | 'quote') {

    const { state, dispatch } = ctx();
    const [po, setPo] = useState('');

    const [billingAddress, setBillingAddress] = useState(undefined);
    const [shippingAddress, setShippingAddress] = useState(undefined);
    const [comments, setComments] = useInput('');
    const [placing, setPlacing] = useState(false);

    const getSelectedBillingAddress = () => billingAddress ?? state.user?.default_billing;
    const getSelectedShippingAddress = () => shippingAddress ?? state.user?.default_shipping;

    useEffect(() => {
        if (!state.user) {
            route('/login');
            return;
        }
        if (!localStorage.getItem('token')) {
            return;
        }
        api.getAddresses()
            .then(addresses => {
                dispatch({ type: 'SET_ADDRESSES', addresses });
            });
    }, []);

    const onPunchoutClick = () => {

        const items = JSON.parse(JSON.stringify(state[kind].items));
        items.forEach((item: CartItem) => {
            item.packs = item.packs.filter(it => it.quantity > 0);
        });

        punchout.submitCart({
            items,
            currency: getCurrency(state)
        })
            .then(() => { });
    };

    const onPlaceOrderClick = (draft = false) => {
        const billingAddress = getSelectedBillingAddress();
        const shippingAddress = getSelectedShippingAddress();

        if (!billingAddress || !shippingAddress) {
            alert('Please select a billing and shipping address');
            return;
        }
        if (kind == 'cart' && !po) {
            alert('Please fill in the PO number');
            return;
        }

        /*OrderFormItem*/

        const taxRate = state.user?.tax_rate || 0;
        const items: OrderFormItem[] = state[kind].items
            .reduce((p, n) => {
                const packs: OrderFormItem[] = n.packs.filter(it => it.quantity > 0)
                    .map(it => ({
                        sku: it.pack.sku || (n.chemical.code + '-' + it.pack.size.replace(/\s/g, '').toUpperCase()),
                        name: n.chemical.name,
                        price: it.pack.price,
                        quantity: it.quantity,
                        id: it.pack.id,
                        tax: Math.round(it.pack.price * it.quantity * taxRate * 10),
                    }));
                return [...p, ...packs]
            }, [] as OrderFormItem[]);

        if (kind == 'quote') {
            for (const item of items) {
                if (item.sku.endsWith('-')) {
                    alert('Please fill in the quantity for ' + item.sku.slice(0, -1));
                    return;
                }
            }
        }

        const billing = netsuiteAddressToAddress(state.addresses.find(it => it.id == billingAddress) as any);
        const shipping = netsuiteAddressToAddress(state.addresses.find(it => it.id == shippingAddress) as any);

        setPlacing(true);


        const place = kind == 'cart' ? api.placeOrder : api.placeQuote;
        place({
            items,
            currency: state.user?.currency || 'GBP',
            billing,
            shipping,
            po,
            comments,
            status: draft ? 'draft' : 'pending',
        })
            .then(() => {
                alert(`Thank you for your ${kind}.  Our team will be processing it shortly.`);
                dispatch({ type: `${kind}.clear` });
                setPo('');
                route('/');
            })
            .finally(() => setPlacing(false));
    };

    const onPlaceDraftOrderClick = () => {
        return onPlaceOrderClick(true)
    }

    const [importOpen, setImportOpen] = useState(false);
    const [importData, setImportData] = useState('');

    const [loading, setLoading] = useState(false);

    const modalRef = useRef(null as HTMLDialogElement | null);

    const onImportConfirm = () => {
        if (loading) {
            return;
        }

        let data = importData
            .split('\n')
            .map(it => it.split(','))
            .reduce((p, n) => p.concat(n), [])
            .map(it => it.trim())
            .filter(it => it);

        if (!data.length) {
            alert('Please add data to import');
            return;
        }
        setLoading(true);
        cartsearch(data)
            .then(res => {
                return getInventory(res.data.map(it => it.code))
                    .then(packs => {
                        for (const molecule of res.data) {

                            const p = packs.filter(it => it.code == molecule.code).map(pack => ({ pack, quantity: 0 }));
                            const packs2 = p.length ? p : [{
                                quantity: 1, pack: {
                                    code: molecule.code, id: Date.now(),
                                    availability: '', availability_reason: '', price: 0, size: ''
                                }
                            }];
                            dispatch({
                                type: kind == 'cart' ? 'add' : 'quote.add',
                                chemical: {...molecule,unspsc: molecule.sds.custrecord_fc_unspsc_fccode ?? null},
                                quantity: 1,
                                options: {},
                                packs: packs2,
                            });

                            setImportOpen(false);
                            modalRef.current?.close();
                        }
                    });
            })
            .finally(() => setLoading(false));

    };
    const onImportCancel = () => {
        if (loading) {
            return;
        }
        setImportOpen(false);
        modalRef.current?.close();
    };

    const subtotal = state[kind].items.reduce((p, n) => p + n.packs.reduce((p, n) => p + n.pack.price * n.quantity, 0), 0);
    const taxRate = (state.user?.tax_rate || 0);
    const tax = subtotal * taxRate * 0.01;

    const currency = currencySymbol(getCurrency(state));


    const addCustomQuoteLine = (e: MouseEvent) => {
        e.preventDefault();
        const name = prompt('Enter chemical name');
        if (name) {
            dispatch({
                type: 'quote.add', chemical: {
                    name,
                    code: name,
                    unspsc: null,
                }, quantity: 1, options: {}, packs: [{ quantity: 1, pack: { code: name, id: Date.now(), availability: '', availability_reason: '', price: 0, size: '' } }]
            });
        }
    };

    return html`
    <main id="maincontent" class="page-main">
    <div id="contentarea"></div>

    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">${kind == 'cart' ? 'Shopping Cart' : 'Quote '}</span>
            ${kind == 'cart' ? html`<strong class="cart-total-js">${currency}${subtotal.toFixed(2)}</strong>` : html``}
        </h1>

    </div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <!-- /ko -->
        </div>

    </div>
    <div class="columns">
        <div class="column main">
            <div class="cart-container">
                <div class="cart-summary" style="padding: 30px 40px 0px 40px"><button class="summary title cart-summary-toggle">Summary</button>
                    <div id="cart-totals" class="cart-totals" data-bind="scope:'block-totals'">
                        <!-- ko template: getTemplate() -->
                        <div class="table-wrapper" data-bind="blockLoader: isLoading">
                            <table class="data table totals">
                                <caption class="table-caption" data-bind="text: $t('Total')">Total</caption>
                                <tbody>
                                    <tr class="totals sub">
                                        <th data-bind="i18n: title" class="mark" scope="row">Subtotal</th>
                                        <td class="amount">
                                            <span class="price" data-bind="text: getValue(), attr: {'data-th': title}"
                                                data-th="Subtotal">${currency}0.00</span>
                                        </td>
                                    </tr>
                                    <tr class="totals-tax">
                                        <th data-bind="text: title" class="mark" colspan="1" scope="row">Tax</th>
                                        <td data-bind="attr: {'data-th': title}" class="amount" data-th="Tax">
                                            <span class="price" data-bind="text: getValue()">${currency}0.00</span>
                                        </td>
                                    </tr>
                                    <tr class="grand totals incl">
                                        <th class="mark" scope="row">
                                            <strong data-bind="i18n: inclTaxLabel">Order Total Incl. Tax</strong>
                                        </th>
                                        <td data-bind="attr: {'data-th': inclTaxLabel}" class="amount"
                                            data-th="Order Total Incl. Tax">
                                            <strong><span class="price"
                                                    data-bind="text: getValue()">${currency}0.00</span></strong>
                                        </td>
                                    </tr>
                                    <tr class="grand totals excl">
                                        <th class="mark" scope="row">
                                            <strong data-bind="i18n: exclTaxLabel">Order Total Excl. Tax</strong>
                                        </th>
                                        <td class="amount" data-th="Order Total Excl. Tax">
                                            <strong><span class="price" >${currency}0.00</span></strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /ko -->

                    </div>
                    <ul class="checkout methods items checkout-methods-items">

                        <li class="item">
                            <div class="columns columns-summary summary-js">
                                <div class="column column-36">
                                    <button class="go-back-button">
                                        <span class="icon">
                                            <i class="fas fa-arrow-left"></i>
                                        </span>
                                        <span class="sr-only">
                                            Go back </span>
                                    </button>
                                </div>
                                <div class="column">
                                    <button class="summary">
                                        Summary </button>
                                </div>
                                <div class="column column-36 align-right">
                                    <button class="sign-in-button">
                                        <span class="icon">
                                            <i class="fas fa-sign-in-alt"></i>
                                        </span>
                                        <span class="sr-only">
                                            Sign in </span>
                                    </button>
                                    <button class="cart-button">
                                        <span class="icon">
                                            <i class="fas fa-shopping-cart"></i>
                                        </span>
                                        <span>
                                            0 </span>
                                        <span class="sr-only">
                                            View Shopping Cart </span>
                                    </button>
                                </div>
                            </div>
                            
                            <div style="
                            padding: 0px 0px 35px 0px;">
                            <button onclick=${goBack} class="my-account-link">Back</button>
                            </div>

                            ${(kind == 'cart' && state.user?.punchout_id) ? html`<div class="summary-container">
                                <p>Checkout</p>
                                <div class="checkout-button-wrapper">
                                    <div id="punchout-form">
                                        <button onclick=${() => onPunchoutClick()} type="button" class="action primary checkout">
                                            <span>Punchout</span>
                                        </button>
                                    </div>
                                </div>
                            </div>` : html`<div class="summary-container">
                            <p>${kind == 'cart' ? 'Checkout' : 'REQUEST QUOTE'}</p>
                            <button class="action primary checkout" onclick=${() => { setImportOpen(true); modalRef.current?.showModal(); }}>LIST SEARCH</button>
                            <form id="checkout-doug">
                                <label for="fname">Shipping Address</label>
                                <${AddressList} value=${getSelectedShippingAddress()} onchange=${setShippingAddress}/>
                                <label for="lname">Billing Address</label>
                                <${AddressList} value=${getSelectedBillingAddress()} onchange=${setBillingAddress}/>
                                <label for="lname">PO Number</label>
                                <input value=${po} onchange=${(e: InputEvent) => setPo((e.target as HTMLInputElement).value)} type="text" id="lname" name="lname" />
                            </form>
                            <div class="totals">
                                <div hidden="true">Subtotal: ${currency}${subtotal.toFixed(2)}</div>
                                <div hidden="true">Tax Rate: %${taxRate.toFixed(2)}</div>
                                <div hidden="true">Tax Amount: ${currency}${tax.toFixed(2)}</div>
                                ${kind == 'cart' ? html`<div>Total (excluding applicable taxes and shipping): ${currency}${(subtotal).toFixed(2)}</div>` : html``}
                            </div>
                        </div>
                                
                        <li class="item">
                            <div class="checkout-button-wrapper">
                                <div id="punchout-form">
                                    <button onclick=${() => onPlaceOrderClick()} type="submit" class="action primary checkout ${placing || !state[kind].items.length ? 'disabled' : ''}">
                                        <span>${kind == 'cart' ? 'Place Order' : 'Create Quote'}</span>
                                    </button>
                                    ${kind == 'cart'
                ? html`<button onclick=${onPlaceDraftOrderClick} type="submit" class="action primary checkout ${placing || !state[kind].items.length ? 'disabled' : ''}">
                                            <span>Create Draft Order</span>
                                        </button>`
                : html``
            }
                                    <!--<button type="submit" class="action primary checkout">
                                        <span>Generate Quote</span>
                                    </button>-->
                                </div>
                            </div>

                        </li>
                        `}
                        </li>
                        ${(kind == 'cart' && state.user?.punchout_id) ? html`` : html`
                        <li class="item">
                            <hr class="quote-form-break" />
                            <form action="/requestquote" method="post" id="custom-quote-form" class="form form-cart">
                                <h2><label for="quote-notes">Comments</label></h2>
                                <br />
                                <textarea value=${comments} onchange=${setComments} id="quote-notes" name="quote_notes" maxlength="500"></textarea>
                            </form>
                        </li>` }
                
                        
                    </ul>
                </div>
                <form id="form-validate" class="form form-cart">
                    <${CartLines} kind=${kind} />
                    ${kind == 'quote' ? html`<br/><button onclick=${addCustomQuoteLine} type="submit" class="action primary checkout">
                        <span>Add Custom Product</span>
                    </button>` : html``}
                    <div class="cart main actions">
                        <a class="action continue" href="/" title="Continue Shopping">
                            <span>Continue Shopping</span>
                        </a>
                        <input type="hidden" value="" id="update_cart_action_container" data-cart-item-update="" />
                    </div>
                </form>
            </div>
            <!-- A modal dialog containing a form -->
            <dialog ref=${modalRef} ${importOpen ? 'open' : ''} onclick=${(ev: MouseEvent) => (ev.target as HTMLElement).tagName == 'DIALOG' && !loading ? onImportCancel() : ''} style="padding:0px">
                <div style="padding:20px">
                <p>
                <label>Add list of codes, comma seperated or one per line:
                </label>
                <textarea value=${importData} oninput=${(ev: InputEvent) => setImportData((ev.target as HTMLInputElement).value)}/>
        
                </p>
                <div>
                ${loading
            ? html`<button style="margin-left:10px;width:100px;height:40px"><${Spinner}/></button>`
            : html`<button style="margin-left:10px;width:100px;height:40px" onclick=${() => onImportCancel()} >Cancel</button>`
        }
        ${loading
            ? html`<button style="margin-left:10px;width:100px;height:40px"><${Spinner}/></button>`
            : html`<button style="margin-left:10px;width:100px;height:40px" onclick=${() => onImportConfirm()} >Confirm</button>`
        }
                </div>
                </div>
            </dialog>
        </div>
    </div>

</main>`;
}

function goBack() {
    window.history.back();
}

function CartLines({ kind }: { kind: 'cart' | 'quote' }) {

    const { state, dispatch } = ctx();

    const setQuantity = (pack: PackQuantity, quantity: number) => {
        dispatch({ type: 'quantity', kind, id: pack.pack.id, size: pack.pack.size, quantity });
    };

    const setUnit = (pack: PackQuantity, unit: string) => {
        dispatch({ type: 'unit', kind, id: pack.pack.id, unit });

    };

    const lines = state[kind].items.map(it => (it.packs.map(pack => ({ pack, chemical: it.chemical })))).reduce((p, n) => p.concat(n), []);

    return html`<div class="cart">
    <div class="cart-item">
        <div class="table-columns">
            <div class="table-column">
                <table id="shopping-cart-table" class="shopping-cart-table">
                    <caption class="table-caption">Shopping Cart Items</caption>
                    <thead>
                        <tr>
                            <th class="col pack" scope="col">
                                <span>Pack Size</span>
                            </th>

                            ${kind == 'cart' ? html`<th class="col availability" scope="col">
                                <span>Availability</span>
                            </th>`: html``}

                            ${kind == 'cart' ? html`<th class="col prices" scope="col">
                                <span>Price</span>
                            </th>`: html``}
                            <th class="col qty" scope="col">
                                <span>Quantity</span>
                            </th>
                            ${kind == 'cart' ? html`<th class="col subtotal" scope="col">
                                <span>Subtotal</span>
                            </th>`: html``}
                            <th class="col actions" scope="col">
                                <span>Actions</span>
                            </th>
                        </tr>
                    </thead>
                    ${lines.map(line => html`<tbody class="cart item">
                        <tr class="item-info">
                            <td data-th="Item" class="col pack">
                                ${line.chemical.name} <br/> ${kind == 'cart' ? (line.pack.pack.sku || (line.chemical.code + '-' + line.pack.pack.size.replace(/\s/g, '').toUpperCase())) : line.chemical.code} </td>
                                ${kind == 'cart' ? html`<td class="col availability" data-th="Availability">
                                <span class="table-availability-js">${line.pack.pack.availability?.replace('&gt;', '>')}</span>
                            </td>` : html``}
                            ${kind == 'cart' ? html`<td class="col price" data-th="Price">

                                <span class="price-excluding-tax" data-label="Excl. Tax">
                                    <span class="cart-price">
                                        <span class="price">${currencySymbol(getCurrency(state))}${line.pack.pack.price.toFixed(2)}</span>
                                    </span>

                                </span>
                            </td>` : html``}
                            ${kind == 'cart' ? html`<td class="col qty">
                                <div class="number-input">
                                    <div onClick=${() => setQuantity(line.pack, Math.max(0, line.pack.quantity - 1))}
                                        role="button" class="number-input-prepend">
                                        <span>-</span>
                                    </div>
                                    <input onInput=${(ev: InputEvent) => setQuantity(line.pack, parseFloat((ev.target as HTMLInputElement).value))}
                                    value=${line.pack.quantity} type="number" class="number-input-field"
                                    />
                                    <div onClick=${() => setQuantity(line.pack, line.pack.quantity + 1)}
                                        role="button" class="number-input-append">
                                        <span>+</span>
                                    </div>
                                </div>
                            </td>`: html``}
                            ${kind == 'cart' ? html`<td class="col subtotal" colspan="4">
                                <div class="actions-toolbar">
                                    <span class="table-subtotal-js">${currencySymbol(getCurrency(state))}${(line.pack.pack.price * line.pack.quantity).toFixed(2)}</span>
                                </div>
                            </td>` : html`<td class="col subtotal" colspan="4">
                            <div class="actions-toolbar">
                                <span class="table-subtotal-js">
                                    <input type="text" value=${line.pack.pack.size} onInput=${(ev: Event) => setUnit(line.pack, (ev.target as HTMLInputElement).value)}/>
                                </span>
                            </div>
                        </td>`}
                            <td class="col actions">
                                <div class="cart-item-buttons">
                                    <a href="/p/${line.chemical.code}" target="_blank" class="view-molecule">
                                        
                                    </a>
                                    <a onClick=${() => dispatch({ type: 'remove', kind, id: line.pack.pack.id })} title="Remove item"
                                        class="action
                                        action-delete delete">
                                        <span class="text">
                                            Remove item </span>
                                    </a>
                                </div>

                            </td>
                        </tr>
                    </tbody>`)}
                </table>
            </div>
        </div>
        </div>
</div>`;
}