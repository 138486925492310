import { html } from 'htm/preact';
import { render } from 'preact';
import { useContext, useEffect, useReducer, useRef, useState } from 'preact/hooks';
import { route, Route, Router, RouterOnChangeArgs } from 'preact-router';
import { Link } from 'preact-router//match';
import { createContext } from 'preact';
import { Model, RootReducer, Product, CartItem, Cart, Action, Chemical, Molecule, getInventory, SalesOrder, netsuite } from './model';
import { conceptBlocks, demo } from './data';
import { Advanced, Catalog, Sketcher } from './catalog';
import { useDebounce } from './utils';
import { Details } from './details';
import { CartView, currencySymbol, QuoteView } from './cart';
import * as api from './api';
import { Admin } from './admin';
import * as commerce from './commerce';
import { PrivacyPolicyPage } from './privacy-policy';
import { TermsOfSalePage } from './terms-of-sale';
import { TermsOfServiceEUPage } from './terms-of-service-eu';
import { ModernSlaveryStatementPage } from './modern-slavery-statement';
import { ForgotPassword, ResetPassword } from './reset-password';
import { Landing } from './landing';
import { getCurrency } from './model';


declare var require: any;

export const DEFAULT_STATE = new Model();

const StoreContext = createContext({
    state: DEFAULT_STATE,      // Your default state.
    dispatch: (action: Action) => { },       // Stubbed. Will be replaced.
});


export interface Ctx {
    state: Model;
    dispatch: (action: Action) => void;
}


export const ctx = () => useContext(StoreContext)




const punchoutId = window.location.search
    .substring(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
        (p, n) => {
            p[n[0]] = decodeURIComponent(n[1]);
            return p;
        },
        {} as any,
    ).punchout || localStorage.getItem('punchoutId');

if (punchoutId) {
    localStorage.setItem('punchoutId', punchoutId);
}

function isUuid(str: string) {
    return str.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
}

function App() {

    const [state, dispatch] = useReducer(RootReducer, DEFAULT_STATE);
    (window as any).dispatch = dispatch;
    const store = { state, dispatch };


    let [styles, setStyles] = useState('');


    const handleRoute = (e: RouterOnChangeArgs) => {
        console.log('ROUTE', e);
        let styles = '';
        if (e.url.startsWith('/account')) {
            styles += 'account customer-account-index page-layout-2columns-left';
        } else if (e.url.startsWith('/catalog')) {
            styles += 'page-products page-with-filter catalogsearch-result-index page-layout-2columns-left';
        } else if (e.url.startsWith('/p/')) {
            styles += 'page-product-configurable catalog-product-view product-benzyl-phenyl-ether page-layout-1column';
        } else if (e.url.startsWith('/cart')) {
            styles += 'checkout-cart-index page-layout-1column';
        }

        let url = e.url;
        const last = url.split('/').pop();
        if (last && isUuid(last)) {
            url = url.replace(last, 'details');
        }

        styles += ' route' + (url || 'home').replace(/\//g, '-');
        setStyles(styles);
    };


    if (!punchoutId && false) {
    } else {


        useEffect(() => {
            dispatch({ type: 'products', products: demo.products });
            //fetch('/api/v1/products')
            //  .then(res => res.json())
            //     .then(dispatch());
        }, []);
        return html`<${StoreContext.Provider} value=${store}>
<div class="page-wrapper ${styles}">
<${Header}/>
    <${Router} onChange=${handleRoute}>
        <${Main} default />
        <${Admin} path="/admin/:part*" />
        <${Login} path="/login" />
        <${ResetPassword} path="/reset-password/:token" />
        <${ForgotPassword} path="/forgot-password" />
        <${Register} path="/register" />
        <${Catalog} path="/catalog" state=${state} dispatch=${dispatch}/>
        <${Sketcher} path="/sketcher" state=${state} dispatch=${dispatch} />
        <${Advanced} path="/advanced" state=${state} dispatch=${dispatch} />
        <${ConceptSearch} path="/concept" />
        <${Account} path="/account/:q*" />
        <${CartView} path="/cart" />
        <${QuoteView} path="/quote" />
        <${Details} path="/p/:id" />
        <${Punchout} path="/punchout/:id" />
        <${PrivacyPolicyPage} path="/privacy-policy" />
        <${TermsOfSalePage} path="/terms-of-sale" />
        <${TermsOfServiceEUPage} path="/terms-of-service-eu" />
        <${ModernSlaveryStatementPage} path="/modern-slavery-statement" />
        <${Landing} path="/landing" />
    </${Router}>
    <${Footer}/>
    </div>
</${StoreContext.Provider}>`;
    }

}

function Punchout({ id }: { id: string }) {


    const { state, dispatch } = ctx();
    const [error, setError] = useState('');

    api.punchoutLogin(id)
        .then(res => {
            dispatch({ type: 'logged', ...res });
            route('/');
        })
        .catch(err => {
            setError(err.message);
        });

    return html`${error}`;
}


function Register() {

    const form = useRef<HTMLFormElement>(null);

    const [loading, setLoading] = useState(false);

    const onSubmitClick = (e: Event) => {

        if (loading) {
            return;
        }
        if (!form.current?.checkValidity()) {
            return alert('Please fill out all fields');
        }

        const formData = new FormData(form.current as any);
        const data: any = {};
        formData.forEach((v, k) => {
            data[k] = v;
        });
        console.log(data);
        setLoading(true);
        api.registerNewAccount(data)
            .then(res => {
                alert('Registration successful. The team will prepare your account and reach out to you.');
                route('/');
            })
            .catch(err => {
                alert('An error happened, please try again, if you still receive the same error please reach out to webuser@fluorochem.co.uk with details: '
                    + err.message);
            })
            .finally(() => setLoading(false));

    }


    return html`
    <main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->
        </div>
    </div>
    <div class="columns">
        <div class="column main"><input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
            </div>
            <div class="aw-ca-company-create">
                <form ref=${form} class="custom-account-registration aw-ca__form">
                    <div id="memberDetails" class="userDiv fieldset-wrapper">
                        <div class="fieldset-wrapper-title">
                            <h2>Contact Information</h2>
                        </div>
                        <fieldset class="fieldset">
                            <div class="field">
                                <label class="label">First Name*</label>
                                <input name="firstname" type="text" class="control" required/>
                            </div>

                            <div class="field">
                                <label class="label">Last Name*</label>
                                <input name="lastname" type="text" class="control" required/>
                            </div>

                            <div class="field">
                                <label class="label">Email*</label>
                                <input name="email" type="email" class="control" required/>
                            </div>
                            <div class="field">
                                <label class="label">Company / Institute*</label>
                                <input name="company" type="text" class="control"  required/>
                            </div>

                        </fieldset>

                        <div style="display: block;">
                            <button type="button" style="display: inline;" onclick=${onSubmitClick}> Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>`;
}

function MyAccount() {

    const { state, dispatch } = ctx();
    const user = state.user;

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            return;
        }
        api.getAddresses()
            .then(addresses => {
                dispatch({ type: 'SET_ADDRESSES', addresses });
            });
    }, []);

    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">My Account</span>
        </h1>
    </div>
    <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
    <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
    </div>

    <div class="block block-dashboard-info">
        <div class="block-title">
            <h2>Account Information</h2>
        </div>
        <div class="block-content">
            <div class="box box-information">
                <h3 class="box-title">
                    <span>Contact Information</span>
                </h3>
                <div class="box-content">
                    <p>
                        ${user?.firstname} ${user?.lastname}<br/>
                        ${user?.email}<br/>
                        ${user?.companyname}<br/>
                        ${user?.cus}<br/>
                    </p>
                </div>
                <!--<div class="box-actions">
                    <a class="action edit primary"
                        href="/account/edit">
                        <span>Edit</span>
                    </a>
                    <a href="/account/edit"
                        class="action change-password primary">
                        Change Password </a>
                </div>-->
            </div>
        </div>
    </div>
    <div class="block block-dashboard-addresses">
        <div class="block-title">
            <h2>Address Book</h2>
        </div>
        <div class="block-content block-addresses">
            <div class="box box-billing-address">
                <h3 class="box-title">
                    <span>Default Billing Address</span>
                </h3>
                <div class="box-content">
                    <address>
                        ${state.user?.default_billing
            ? state.addresses.find(it => it.id == state.user?.default_billing)?.addrtext
            : `You have not set a default billing address.`
        }
                    </address>
                </div>
                <div class="box-actions">
                    <a class="action edit primary"
                        href="/account/addresses"
                        data-ui-id="default-billing-edit-link">
                        <span>Edit Address</span></a>
                </div>
            </div>
            <div class="box box-shipping-address">
                <h3 class="box-title">
                    <span>Default Shipping Address</span>
                </h3>
                <div class="box-content">
                    <address>
                        ${state.user?.default_shipping
            ? state.addresses.find(it => it.id == state.user?.default_shipping)?.addrtext
            : `You have not set a default shipping address.`
        }
                    </address>
                </div>
                <div class="box-actions">
                    <a class="action edit primary"
                        href="/account/addresses"
                        data-ui-id="default-shipping-edit-link">
                        <span>
                            Edit Address </span>
                    </a>
                </div>
            </div>
        </div>
        <div class="block-content address-actions">
            <a class="action edit primary" href="/account/addresses">
                <span>Manage Addresses</span>
            </a>
        </div>
    </div>
</div>`;
}

function AccountMenu() {

    const { state, dispatch } = ctx();
    const logout = () => {
        dispatch({ type: 'logout' });
        route('/');
    };

    return html`<div class="sidebar sidebar-main">
    <div class="block block-collapsible-nav">
        <div class="title block-collapsible-nav-title"
            data-mage-init='{"toggleAdvanced": {"toggleContainers": "#block-collapsible-nav", "selectorsToggleClass": "active"}}'>
            <strong>
                My Account </strong>
        </div>
        <div class="content block-collapsible-nav-content" id="block-collapsible-nav">
            <ul class="nav items">
                <${Link} class="nav item" activeClassName="current" href="/account/orders">
                    <a>My Orders</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/draft-orders">
                    <a>My Draft Orders</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/quotes">
                    <a>My Quotes</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/addresses">
                    <a>Address Book</a>
                <//>
                <${Link} class="nav item" activeClassName="current" href="/account/me">
                    <a>Profile</a>
                <//>
                <${Link} class="nav item" onClick=${logout}>
                    <a>Log out</a>
                </li>
            </ul>
        </div>
    </div>
</div>`;
}

function AccountInfo() {

    const { state, dispatch } = ctx();
    const user = state.user;

    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">Edit Account Information</span>
        </h1>
    </div>
    <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
    <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
    </div>
    <form class="form form-edit-account" action="/account/editPost/" method="post"
        id="form-validate" enctype="multipart/form-data" data-hasrequired="&#x2A;&#x20;Required&#x20;Fields"
        autocomplete="off">
        <fieldset class="fieldset info">
            <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
            <legend class="legend"><span>Account Information</span></legend><br/>

            <div class="field field-name-firstname required">
                <label class="label" for="firstname"><span>First Name</span></label>
                <div class="control">
                    <input type="text" id="firstname" name="firstname" value="${user?.firstname}" title="First&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>
            <div class="field field-name-lastname required">
                <label class="label" for="lastname"><span>Last Name</span></label>
                <div class="control">
                    <input type="text" id="lastname" name="lastname" value="${user?.lastname}" title="Last&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>

            <!--
            <div class="field choice">
                <input type="checkbox" name="change_email" id="change-email" data-role="change-email" value="1"
                    title="Change&#x20;Email" class="checkbox" />
                <label class="label" for="change-email"><span>Change Email</span></label>
            </div>
            <div class="field choice">
                <input type="checkbox" name="change_password" id="change-password" data-role="change-password" value="1"
                    title="Change&#x20;Password" class="checkbox" />
                <label class="label" for="change-password"><span>Change Password</span></label>
            </div>
            -->
        </fieldset>

        <fieldset class="fieldset password" data-container="change-email-password">
            <legend class="legend"><span data-title="change-email-password">Change Email and Password</span></legend>
            <br/>
            <div class="field email required" data-container="change-email">
                <label class="label" for="email"><span>Email</span></label>
                <div class="control">
                    <input type="email" name="email" id="email" autocomplete="email" data-input="change-email"
                        value="testerucb1&#x40;punchout.live" title="Email" class="input-text"
                        data-validate="{required:true, 'validate-email':true}" />
                </div>
            </div>
            <div class="field password current required">
                <label class="label" for="current-password"><span>Current Password</span></label>
                <div class="control">
                    <input type="password" class="input-text" name="current_password" id="current-password"
                        data-input="current-password" autocomplete="off" />
                </div>
            </div>
            <div class="field new password required" data-container="new-password">
                <label class="label" for="password"><span>New Password</span></label>
                <div class="control">
                    <input type="password" class="input-text" name="password" id="password" data-password-min-length="8"
                        data-password-min-character-sets="3" data-input="new-password"
                        data-validate="{required:true, 'validate-customer-password':true}" autocomplete="off" />
                    <div id="password-strength-meter-container" data-role="password-strength-meter" aria-live="polite">
                        <div id="password-strength-meter" class="password-strength-meter">
                            Password Strength:
                            <span id="password-strength-meter-label" data-role="password-strength-meter-label">
                                No Password </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field confirm password required" data-container="confirm-password">
                <label class="label" for="password-confirmation"><span>Confirm New Password</span></label>
                <div class="control">
                    <input type="password" class="input-text" name="password_confirmation" id="password-confirmation"
                        data-input="confirm-password" autocomplete="off" />
                </div>
            </div>
        </fieldset>
        <div class="actions-toolbar">
            <div class="primary">
                <button type="submit" class="action save primary" title="Save"><span>Save</span></button>
            </div>
            <div class="secondary">
                <a class="action back" href="/"><span>Go back</span></a>
            </div>
        </div>
    </form>
</div>`;
}
function Account() {

    const { state } = ctx();

    if (!state.user) {
        return route('/login');
    }

    return html`
        <main id="maincontent" class="page-main">
            <div id="contentarea"></div>
            <div class="page messages">
                <div data-placeholder="messages"></div>
                <div data-bind="scope: 'messages'">
                    <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->

                    <!-- ko if: messages().messages && messages().messages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }"
                        class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->
                </div>
            </div>
            <div class="columns">
                
            <${Router}>
                <${MyAccount} path="/account/me" />
                <${Orders} path="/account/orders" />
                <${DraftOrders} path="/account/draft-orders" />
                <${Quotes} path="/account/quotes" />
                <${OrderDetails} path="/account/order/:id" />
                <${QuoteDetails} path="/account/quote/:id" />
                <${Addresses} path="/account/addresses" />
                <${AddNewAddress} path="/account/address-new" />
                <${AccountInfo} path="/account/edit" />
            </${Router}>
            <${AccountMenu}/>

            </div>
        </main>`;
}

function Orders() {
    return OrdersOrQuotes('orders');
}

function DraftOrders() {
    return OrdersOrQuotes('orders', true);
}

function Quotes() {
    return OrdersOrQuotes('quotes');
}


function OrdersOrQuotes(kind: 'orders' | 'quotes', draft = false) {

    const { state, dispatch } = ctx();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (kind == 'orders') {
            if (draft) {
                api.getDraftOrders()
                    .then(orders => {
                        dispatch({ type: 'SET_ORDERS', orders });
                        setLoading(false);
                    });
            } else {
                api.getOrders()
                    .then(orders => {
                        dispatch({ type: 'SET_ORDERS', orders });
                        setLoading(false);
                    });
            }
        } else {
            api.getQuotes()
                .then(quotes => {
                    dispatch({ type: 'SET_QUOTES', quotes });
                    setLoading(false);
                });
        }
        setLoading(true);
    }, []);

    if (loading) {
        return html`<div class="column main">
        <br/><br/>
        <${Loading}/>
        </div>`;
    }

    return html`<div class="column main">
        <div class="page-title-wrapper">
            <h1 class="page-title">
                <span class="base" data-ui-id="page-title-wrapper">My ${kind == 'orders' ? (draft ? 'Draft ' : '') + 'Orders' : 'Quotes'}</span>
            </h1>
        </div>
        <table class="striped">
        <thead>
            <tr>
                <th>#</th>
                <th style="width:200px">PO #</th>
                <th style="width:100px">Total</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            ${state[kind].items.map(order => 'gid' in order ?
        html`<tr>
                <td>${order.id}</td>
                <td>${order.po}</td>
                <td style="text-align:right;padding-right:10px">${currencySymbol(order.currency)}${(order.items.reduce((p, n) => p + n.price * n.quantity, 0) * 0.001).toFixed(2)}</td>
                <td>${order.created_at}</td>
                <td></td>
                <td></td>
                <td>Pending</td>
                <td class="a-right last"><a href="/account/${kind == 'orders' ? 'order' : 'quote'}/${order.gid}">View ${draft ? 'Draft ' : ''}${kind == 'orders' ? 'Order' : 'Estimate'}</a></td>
            </tr>`
        : html`
            <tr>
                <td>${order.tranid}</td>
                <td>${order.otherrefnum}</td>
                <td style="text-align:right;padding-right:10px">${currencySymbol(getCurrency(state))}${parseFloat(order.foreigntotal).toFixed(2)}</td>
                <td>${order.createddate}</td>
                <td>${getStatus(kind, order.status)}</td>
                <td class="a-right last"><a href="/account/${kind == 'orders' ? 'order' : 'quote'}/${order.id}">View ${kind == 'orders' ? 'Order' : 'Estimate'}</a></td>
            </tr>
            `)}
        </tbody>
    </table>
    </div>`;

}



const netsuiteStatusMap: { orders: { [key: string]: string }, quotes: { [key: string]: string } } = {
    orders: {
        A: 'Pending Approval',
        B: 'Pending Fulfillment',
        C: 'Cancelled',
        D: 'Partially Fulfilled',
        E: 'Pending Billing/Partially Fulfilled',
        F: 'Pending Billing',
        G: 'Billed',
        H: 'Closed'
    },
    quotes: {
        A: 'Open',
        B: 'Processed',
        C: 'Closed',
        V: 'Voided',
        X: 'Expired'
    }
}

function getStatus(kind: 'orders' | 'quotes', status: string) {
    return netsuiteStatusMap[kind][status] || status;
}

function OrderDetails(params: { id: string }) {
    return OrderOrQuoteDetails('order', params);
}

function QuoteDetails(params: { id: string }) {
    return OrderOrQuoteDetails('quote', params);
}

function OrderOrQuoteDetails(kind: 'order' | 'quote', { id }: { id: string }) {

    const { dispatch } = ctx();


    const [order, setOrder] = useState(null as any as (netsuite.Order | netsuite.Estimate | commerce.Order));


    useEffect(() => {
        if (kind == 'order') {
            api.getOrder(id)
                .then(order => {
                    setOrder(order);
                });
        } else {
            api.getQuote(id)
                .then(quote => {
                    setOrder(quote);
                });
        }
    }, []);

    const currency = currencySymbol(order ? ('gid' in order ? order.currency : (order.type == 'estimate' ? order?.currencysymbol : order.currency.refName)) || 'USD' : '');

    if (order && 'gid' in order) {
        order.shipping_cost = order.shipping_cost || 0;
        order.subtotal = order.items.reduce((p, n) => p + n.quantity * n.price, 0) * 0.001;
        order.total = order.subtotal + order.shipping_cost + order.items.reduce((p, n) => p + (n.tax || 0), 0) * 0.001;
    }

    const onApproveClick = () => {
        if (order && 'gid' in order) {
            api.approveOrder(order.gid)
                .then(() => {
                    setOrder({ ...order, status: 'pending' });
                    alert('Order approved');
                });
        }
    };

    const onDraftEditClick = () => {
        if (!(order && 'gid' in order && order.status == 'draft')) {
            return alert('Can only be used with draft order');
        }
        if (confirm('Are you sure you want to edit this draft?')) {
            api.deleteDraft(order.gid)
                .then(() => {
                    dispatch({ type: 'draft.to.cart', value: order });
                    route('/cart');
                });
        }
    }

    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">${kind == 'order' ? (order?.status == 'draft' ? 'Draft Order' : 'Order') : 'Estimate'} Details</span>
            
        </h1>
    </div>
        ${order ? ('gid' in order ?
            html`<div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
            <!--begin::Orders-->
            <div class="d-flex flex-column gap-7 gap-lg-10">
                <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                    <!--begin::Payment address-->
    <div class="card card-flush py-4 flex-row-fluid position-relative">
    <!--begin::Background-->
    <div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
        <i class="ki-solid ki-two-credit-cart" style="font-size: 14em">
        </i>
    </div>
    <!--end::Background-->
    
    <!--begin::Card header-->
    <div class="card-header">
        <div class="card-title">
            <h2>Billing Address</h2>
        </div>
    </div>
    <!--end::Card header-->
    
    <!--begin::Card body-->
    <div class="card-body pt-0">
        ${order.billing.deliver_to.join(', ')},<br/>
        ${order.billing.street.join(', ')},<br/>
        ${order.billing.city} ${order.billing.postal_code},<br/>
        ${order.billing.state},<br/>
        ${order.billing.country}.
    </div>
    <!--end::Card body-->
    </div>
    <!--end::Payment address-->
                    <!--begin::Shipping address-->
    <div class="card card-flush py-4 flex-row-fluid position-relative">
    <!--begin::Background-->
    <div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
        <i class="ki-solid ki-delivery" style="font-size: 13em">
        </i>
    </div>
    <!--end::Background-->
    
    <!--begin::Card header-->
    <div class="card-header">
        <div class="card-title">
            <h2>Shipping Address</h2>
        </div>
    </div>
    <!--end::Card header-->
    
    <!--begin::Card body-->
    <div class="card-body pt-0">
        ${order.shipping.deliver_to.join(', ')},<br/>
        ${order.shipping.street.join(', ')},<br/>
        ${order.shipping.city} ${order.shipping.postal_code},<br/>
        ${order.shipping.state},<br/>
        ${order.shipping.country}.
    </div>
    <!--end::Card body-->
    </div>
    <!--end::Shipping address-->                </div>
    
                <!--begin::Product List-->
    <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
    <!--begin::Card header-->
    <div class="card-header">
        <div class="card-title">
            <h2>${kind == 'order' ? 'Order' : 'Estimate'} #${order.id} (PO: ${order.po})</h2>
        </div>
    </div>
    <!--end::Card header-->
    
    <!--begin::Card body-->
    <div class="card-body pt-0">
        <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                <thead>
                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                        <th class="min-w-175px">Product</th>
                        <th class="min-w-100px text-end">SKU</th>
                        <th class="min-w-70px text-end">Qty</th>
                        <th class="min-w-100px text-end">Unit Price</th>
                        <th class="min-w-100px text-end">Total</th>
                    </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                    ${order.items.map(item => html`<tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <!--begin::Thumbnail-->
                                <a href="/p/${item.sku.split('-')[0]}" target="_blank" class="symbol symbol-50px">
                                    <span class="symbol-label" style="background-image:url(${api.getMoleculeSvg(item.sku.split('-')[0])});"></span>
                                </a>
                                <!--end::Thumbnail-->
    
                                <!--begin::Title-->
                                <div class="ms-5">
                                    <a href="/p/${item.sku.split('-')[0]}" target="_blank" class="fw-bold text-gray-600 text-hover-primary">${item.name}</a>
                                    <div class="fs-7 text-muted">Expected Ship Date: </div>
                                </div>
                                <!--end::Title-->
                            </div>
                        </td>
                        <td class="text-end">
                            ${item.sku}                        </td>
                        <td class="text-end">
                            ${item.quantity}
                        </td>
                        <td class="text-end">
                            ${currency}${(item.price * 0.001).toFixed(2)}
                        </td>
                        <td class="text-end">
                            ${currency}${(item.price * item.quantity * 0.001).toFixed(2)}
                        </td>
                    </tr>`)} 
                    <tr hidden>
                        <td colspan="4" class="text-end">
                            Subtotal
                        </td>
                        <td class="text-end">
                            ${currency}${order.subtotal?.toFixed(2)}
                        </td>
                    </tr>
                    <tr hidden>
                        <td colspan="4" class="text-end">
                            VAT (${(100 * ((order.total || 0) - (order.subtotal || 0) - (order.shipping_cost || 0)) / ((order.subtotal || 0) + (order.shipping_cost || 0))).toFixed(2)}%)
                        </td>
                        <td class="text-end">
                            ${currency}${((order.total || 0) - (order.subtotal || 0) - (order.shipping_cost || 0)).toFixed(2)}
                        </td>
                    </tr>
                    <tr hidden>
                        <td colspan="4" class="text-end">
                            Shipping Rate
                        </td>
                        <td class="text-end">
                            ${currency}${order.shipping_cost?.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="fs-3 text-dark text-end">
                            Total
                        </td>
                        <td class="text-dark fs-3 fw-bolder text-end">
                            ${currency}${order.total?.toFixed(2)}
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--end::Table-->
        </div>
    </div>
    <!--end::Card body-->
    </div>
    <!--end::Product List-->            </div>
    ${order.status == 'draft' ? html`<div class="draft-actions">
        <button onclick=${onDraftEditClick} >Edit</button><button onclick=${onApproveClick} >Approve</button>
        </div>` : html``}

            <!--end::Orders-->
        </div>`

            : (order.type !== 'estimate' ? html`<div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
        <!--begin::Orders-->
        <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                <!--begin::Payment address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
    <i class="ki-solid ki-two-credit-cart" style="font-size: 14em">
    </i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
    <div class="card-title">
        <h2>Billing Address</h2>
    </div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
    ${order.billingAddress?.addressee},<br/>
    ${order.billingAddress.addr1},<br/>
    ${order.billingAddress.city} ${order.billingAddress.zip},<br/>
    ${order.billingAddress.state},<br/>
    ${order.billingAddress.country}.
</div>
<!--end::Card body-->
</div>
<!--end::Payment address-->
                <!--begin::Shipping address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
    <i class="ki-solid ki-delivery" style="font-size: 13em">
    </i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
    <div class="card-title">
        <h2>Shipping Address</h2>
    </div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
    ${order.shippingAddress.addressee},<br/>
    ${order.shippingAddress.addr1},<br/>
    ${order.shippingAddress.city} ${order.shippingAddress.zip},<br/>
    ${order.shippingAddress.state},<br/>
    ${order.shippingAddress.country}.
</div>
<!--end::Card body-->
</div>
<!--end::Shipping address-->                </div>

            <!--begin::Product List-->
<div class="card card-flush py-4 flex-row-fluid overflow-hidden">
<!--begin::Card header-->
<div class="card-header">
    <div class="card-title">
        <h2>Order #${order.tranId} (PO: ${order.otherRefNum})</h2>
    </div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
    <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
            <thead>
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-175px">Product</th>
                    <th class="min-w-100px text-end">SKU</th>
                    <th class="min-w-70px text-end">Qty</th>
                    <th class="min-w-100px text-end">Unit Price</th>
                    <th class="min-w-100px text-end">Total</th>
                </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
                ${order.item.items.map(item => html`<tr>
                    <td>
                        <div class="d-flex align-items-center">
                            <!--begin::Thumbnail-->
                            <a href="/p/${item.item.refName.split('-')[0]}"  target="_blank" class="symbol symbol-50px">
                                <span class="symbol-label" style="background-image:url(${api.getMoleculeSvg(item.item.refName.split('-')[0])});"></span>
                            </a>
                            <!--end::Thumbnail-->

                            <!--begin::Title-->
                            <div class="ms-5">
                                <a href="/p/${item.item.refName.split('-')[0]}" target="_blank" class="fw-bold text-gray-600 text-hover-primary">${item.description}</a>
                                <div class="fs-7 text-muted">Expected Ship Date: ${item.expectedShipDate}</div>
                                <div class="fs-7 text-muted">Tracking Numbers: ${order.linkedTrackingNumbers}</div>
                            </div>
                            <!--end::Title-->
                        </div>
                    </td>
                    <td class="text-end">
                        ${item.item.refName}                        </td>
                    <td class="text-end">
                        ${item.quantity}
                    </td>
                    <td class="text-end">
                        ${currency}${item.amount}
                    </td>
                    <td class="text-end">
                        ${currency}${item.amount * item.quantity}
                    </td>
                </tr>`)} 
                <tr>
                    <td colspan="4" class="text-end">
                        Subtotal
                    </td>
                    <td class="text-end">
                        ${currency}${order.subtotal}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="text-end">
                        VAT (${(100 * (order.total - order.subtotal - order.shippingCost) / (order.subtotal + order.shippingCost)).toFixed(2)}%)
                    </td>
                    <td class="text-end">
                        ${currency}${(order.total - order.subtotal - order.shippingCost).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="text-end">
                        Shipping Rate
                    </td>
                    <td class="text-end">
                        ${currency}${order.shippingCost.toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="fs-3 text-dark text-end">
                        Total
                    </td>
                    <td class="text-dark fs-3 fw-bolder text-end">
                        ${currency}${order.total.toFixed(2)}
                    </td>
                </tr>
            </tbody>
        </table>
        <!--end::Table-->
    </div>
</div>
<!--end::Card body-->
</div>
<!--end::Product List-->            </div>
        <!--end::Orders-->
    </div>` : html`<div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
    <!--begin::Orders-->
    <div class="d-flex flex-column gap-7 gap-lg-10">
        <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
            <!--begin::Payment address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
<i class="ki-solid ki-two-credit-cart" style="font-size: 14em">
</i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
<div class="card-title">
    <h2>Billing Address</h2>
</div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
${order.billingaddress_text.split('\n').map(it => html`${it}<br/>`)}
</div>
<!--end::Card body-->
</div>
<!--end::Payment address-->
            <!--begin::Shipping address-->
<div class="card card-flush py-4 flex-row-fluid position-relative">
<!--begin::Background-->
<div class="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
<i class="ki-solid ki-delivery" style="font-size: 13em">
</i>
</div>
<!--end::Background-->

<!--begin::Card header-->
<div class="card-header">
<div class="card-title">
    <h2>Shipping Address</h2>
</div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
${order.billingaddress_text.split('\n').map(it => html`${it}<br/>`)}
</div>
<!--end::Card body-->
</div>
<!--end::Shipping address-->                </div>

        <!--begin::Product List-->
<div class="card card-flush py-4 flex-row-fluid overflow-hidden">
<!--begin::Card header-->
<div class="card-header">
<div class="card-title">
    <h2>Estimate #${order.tranid}</h2>
</div>
</div>
<!--end::Card header-->

<!--begin::Card body-->
<div class="card-body pt-0">
<div class="table-responsive">
    <!--begin::Table-->
    <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">
        <thead>
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-175px">Product</th>
                <th class="min-w-100px text-end">SKU</th>
                <th class="min-w-70px text-end">Qty</th>
                <th class="min-w-100px text-end">Unit Price</th>
                <th class="min-w-100px text-end">Total</th>
            </tr>
        </thead>
        <tbody class="fw-semibold text-gray-600">
            ${order.item.items.map(item => html`<tr>
                <td>
                    <div class="d-flex align-items-center">
                        <!--begin::Thumbnail-->
                        <a href="/p/${item.item_display.split('-')[0]}" target="_blank" class="symbol symbol-50px">
                            <span class="symbol-label" style="background-image:url(${api.getMoleculeSvg(item.item_display.split('-')[0])});"></span>
                        </a>
                        <!--end::Thumbnail-->

                        <!--begin::Title-->
                        <div class="ms-5">
                            <a href="/p/${item.item_display.split('-')[0]}" target="_blank" class="fw-bold text-gray-600 text-hover-primary">${item.description}</a>
                        </div>
                        <!--end::Title-->
                    </div>
                </td>
                <td class="text-end">
                    ${item.item_display}                        </td>
                <td class="text-end">
                    ${item.quantity}
                </td>
                <td class="text-end">
                    ${currency}${item.amount}
                </td>
                <td class="text-end">
                    ${currency}${(parseFloat(item.amount) * parseFloat(item.quantity)).toFixed(2)}
                </td>
            </tr>`)} 
            <tr>
                <td colspan="4" class="text-end">
                    Subtotal
                </td>
                <td class="text-end">
                    ${currency}${parseFloat(order.subtotal).toFixed(2)}
                </td>
            </tr>
            <tr>
                <td colspan="4" class="text-end">
                    VAT (${(100 * (parseFloat(order.total) - parseFloat(order.subtotal) - parseFloat(order.custbody_cat_shipping_cost_total)) / (parseFloat(order.subtotal) + parseFloat(order.custbody_cat_shipping_cost_total))).toFixed(2)}%)
                </td>
                <td class="text-end">
                    ${currency}${(parseFloat(order.total) - parseFloat(order.subtotal) - parseFloat(order.custbody_cat_shipping_cost_total)).toFixed(2)}
                </td>
            </tr>
            <tr>
                <td colspan="4" class="text-end">
                    Shipping Rate
                </td>
                <td class="text-end">
                    ${currency}${parseFloat(order.custbody_cat_shipping_cost_total).toFixed(2)}
                </td>
            </tr>
            <tr>
                <td colspan="4" class="fs-3 text-dark text-end">
                    Total
                </td>
                <td class="text-dark fs-3 fw-bolder text-end">
                    ${currency}${parseFloat(order.total).toFixed(2)}
                </td>
            </tr>
        </tbody>
    </table>
    <!--end::Table-->
</div>
</div>
<!--end::Card body-->
</div>
<!--end::Product List-->            </div>
    <!--end::Orders-->
</div>`)) : 'Loading...'}

    </div>`;
}
export interface Address {
    id: number;
    attention: string;
    addressee: string;
    addr1: string;
    addr2: string;
    addrphone: string;
    addrtext: string;
    city: string;
    country: string;
    state: string;
    zip: string;
}

function Addresses() {

    const { state, dispatch } = ctx();

    const [selected, setSelected] = useState<Address | null>(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            return;
        }
        api.getAddresses()
            .then(addresses => {
                dispatch({ type: 'SET_ADDRESSES', addresses });
            });
    }, []);


    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">My Company Addresses 
            <!--<a href="/account/address-new" class="action primary">Add New Address</a>--></span>
        </h1>
        <div style="display:flex;gap: 10px; align-items:center">
            <input onInput=${(e: InputEvent) => setSearch((e.currentTarget as HTMLInputElement).value)} value=${search} type="text" placeholder="Search" />
            <button onclick=${() => selected ? dispatch({ type: 'user.billing', id: selected.id }) : null} style="width:240px" class="action primary ${selected && selected?.id == state.user?.default_billing ? 'disabled' : ''}">Set As Billing Default</button>
            <button onclick=${() => selected ? dispatch({ type: 'user.shipping', id: selected.id }) : null} style="width:240px" class="action primary ${selected && selected?.id == state.user?.default_shipping ? 'disabled' : ''}">Set As Shipping Default</button>
        </div>
    </div>
    <table class="striped">
    <thead>
    <tr style="font-weight:bold">
        <td></td>
        <td>Addressee</td>
        <td>Attention</td>
        <td>Street Address</td>
        <td>City</td>
        <td>Country</td>
        <td>State</td>
        <td>Zip/Postal Code</td>
        <td>Phone</td>
    </tr>
    </thead>
    <tbody>
        ${state.addresses
            .filter(it => Object.keys(it).filter(key => (it as any)[key]).find(key => (it as any)[key].toString().toLowerCase().includes(search.toLowerCase())))
            .sort((p, n) => p.addr1 > n.addr1 ? 1 : -1)
            .map(address => html`
        <tr style="border-top:lightgrey solid 2px;border-bottom:lightgrey solid 2px">
            <td><input onclick=${() => setSelected(address)} type="radio" name="selected" value=${address.id} /></td>
            <td>${address.attention}</td>
            <td>${address.addressee}</td>
            <td>${address.addr1}, ${address.addr2}</td>
            <td>${address.city}</td>
            <td>${address.country}</td>
            <td>${address.state}</td>
            <td>${address.zip}</td>
            <td>${address.addrphone}</td>
        </tr>
        `)}
    </tbody>
        
    </table>
    </div>`;
}


const subsidiariesMap: { [key: string]: string } = { "6": "IE", "10": "NL", "8": "GB", "11": "NL", "1": "GB", "13": "GB", "12": "GB", "2": "GB", "9": "GB", "7": "NL", "3": "GB" }
const countriesMap: { [key: string]: string } = {
    GB: "United Kingdom",
    IE: "Ireland",
    NL: "Netherlands",

};


function Footer() {

    const { state } = ctx();



    return html`<footer class="page-footer">
    <div class="footer">
        <div class="footer-top">
            <div class="footer-logo">
                <p>Powered by:
                    <img src="/assets/images/fluorochem_logo.svg" alt="Fluorochem" class="fluorochem-logo"/>
                </p>
            </div>
            <div class="footer-row footer-top-content">
                <div class="footer-column">
                    <ul class="footer-switcher footer-links">
                        <li><span class="active">${countriesMap[subsidiariesMap[state.user?.subsidiary || 1]]}</span></li>
                    </ul>
                </div>
                <div class="footer-column">
                <ul class="footer-links">
                    <li class="nav item"><a href="/terms-of-service-eu/">Terms of Service EU</a></li>
                    <li class="nav item"><a href="/privacy-policy/">Privacy Policy</a></li>
                    <li class="nav item"><a href="/terms-of-sale/">Terms of Sale</a></li>
                    <li class="nav item"><a href="/modern-slavery-statement">Modern Slavery Statement</a></li>
                </ul>
            </div>
            </div>
        </div>
    </div>
</footer>`;
}


function HeaderLogo() {
    const { dispatch } = ctx();
    return html`<div class="header-left"><a class="logo" href="/" title="" onclick=${() => dispatch({ type: 'resetFilters' })}
    aria-label="store logo">
    <img src="${require('/assets/images/d.png')}"
        title="" alt="" />
</a>
<a class="action skip content
area" href="#contentarea">
    <span>
        Skip to Content </span>
</a>
</div>`;
}


function countItems(items: CartItem[]) {
    return items.reduce((p, n) => p + n.packs.reduce((p, n) => p + n.quantity, 0), 0);
}


export function LanguageSelector() {

    const { state, dispatch } = ctx();

    const selectLocation = (e: InputEvent) => {
        dispatch({ type: 'location.set', location: (e.target as HTMLSelectElement).value });
        location.reload();
    };

    return html`<select onchange=${selectLocation} value=${state.location} class="location-selector" style="background-color: #e0e1e2;color: #333;font-weight: bold;height: 36px;">
    <option value="">Select Location</option>
    <option value="GB">United Kingdom</option>
    <option value="EU">Europe</option>
    <option value="ROW">Rest Of the World</option>
</select>`;
}

function Header() {

    const { state, dispatch } = ctx();

    return html`<header class="page-header">
    <div class="header content">
    ${state.user ? html`` : html`<a href="https://www.fluorochem.co.uk"><button class="fluo-back-btn">Back to fluorochem.co.uk</button></a>`}
        <${Router}>
            <${HeaderLogo} default />
            <div path="/" ></div>
        </${Router}>

        <div class="header-right">

            <ul class="header links">
                <style>
                    .location-selector:hover{
                        background-color: #c0c1c2 !important;
                    }
                </style>
                <li class="customer-welcome">                    
                    ${state.user ? html`` :
            html`<${LanguageSelector}/>`}
                </li>
            </ul>

            ${state.user?.catalog_only || state.user?.punchout_id ? html`` : html`<div data-block="minicart" class="minicart-wrapper tooltip">
                <a alt="Quote" class="action showcart showquote" href="/quote"
                    data-bind="scope: 'minicart_content'">                   
                    <span class="counter qty" style="margin-left:-12px">
                        <span class="counter-number">${countItems(state.quote.items)}</span>
                        <span class="counter-label">${countItems(state.quote.items)}</span>
                    </span>
                </a>
                <span class="tooltiptext">View Quote</span>
            </div>`}
            ${state.user?.catalog_only ? html`` : html`<div data-block="minicart" class="minicart-wrapper tooltip">
                <a alt="Cart" class="action showcart" href="/cart"
                    data-bind="scope: 'minicart_content'">
                    <span class="counter qty">
                        <span class="counter-number">${countItems(state.cart.items)}</span>
                        <span class="counter-label">${countItems(state.cart.items)}</span>
                    </span>
                </a>
                <span class="tooltiptext">View Cart</span>
            </div>`}

            <ul class="header links">
                <li class="customer-welcome">
                    <span class="customer-name" role="link" tabindex="0" data-mage-init='{"dropdown":{}}'
                        data-toggle="dropdown" data-trigger-keypress-button="true"
                        data-bind="scope: 'customer'">
                        <button type="button" class="action switch" tabindex="-1"
                            data-action="customer-menu-toggle">
                            <span>Change</span>
                        </button>
                    </span>
                    <div class="customer-menu" data-target="dropdown">
                        <ul class="header links">
                            ${state.user
            ? (state.user.punchout_id
                ? html``
                : html`<li class="authorization-link"><a href="/account/orders" class="my-account-link">My Account</a></li>`
            )
            : html`<li class="authorization-link"><a href="/login" class="login-link">Sign In</a></li>
                                        <li class="authorization-link"><a href="/register" class="login-link">Sign Up</a></li>`
        }                            
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <${Router}>
            <${HeaderSearch} default />
            <div path="/" ></div>
        </${Router}>
    </div>
</header>`;
}

export function useInput(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState(ev.target.value);
        if (callback) callback(ev.target.value);
    }];
}



export function isValidEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}


function Login() {

    const { state, dispatch } = ctx();

    const [email, setEmail] = useInput('');
    const [password, setPassword] = useInput('');
    const [cus, setCus] = useInput('');
    const [code, setCode] = useInput('');

    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState('Login' as 'Login' | 'EmailPasswordReset' | 'SelectCustomer');
    const [customers, setCustomers] = useState([] as any[]);

    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();


        if (!isValidEmail(email)) {
            return alert('Invalid email');
        }

        if (!password || password.length < 6) {
            return alert('Please enter a password of at least 6 characters');
        }

        if (step == 'EmailPasswordReset' && !code) {
            return alert('Please enter the code');
        }

        setLoading(true);
        api.login(email, password, cus, code)
            .then((res) => {
                if (res == 'EmailPasswordReset') {
                    setStep('EmailPasswordReset');
                } else if (res.Logged) {
                    dispatch({ type: 'logged', ...res.Logged });
                    route('/');
                } else if (res.SelectCustomer) {
                    setStep('SelectCustomer');
                    setCustomers(res.SelectCustomer);
                }
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });




    };


    return html`<main id="maincontent" class="page-main">
    <div id="contentarea"></div>
    <div class="page messages">
        <div data-placeholder="messages"></div>
        <div data-bind="scope: 'messages'">
            <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->

            <!-- ko if: messages().messages && messages().messages.length > 0 -->
            <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }" class="messages">
                <div data-bind="attr: {
                class: 'message-' + message.type + ' ' + message.type + ' message',
                'data-ui-id': 'message-' + message.type
            }">
                    <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                </div>
            </div>
            <!-- /ko -->
        </div>
    </div>
    <div class="columns">
        <div class="column main"><input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI" />
            <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
            </div>
            <div class="login-container">              
                <div class="login-container-right">
                    <div class="block block-customer-login">
                        <div class="block-title">
                        <img class="logo" src="${require('/assets/images/d.png')}"
        title="" alt="Doug Discovery" />                           
                        </div>
                        <div class="block-title">
                            <h2 id="block-customer-login-heading">
                                Registered Doug Discovery Users </h2>
                        </div>
                        <div class="block-content" aria-labelledby="block-customer-login-heading">
                            <form onSubmit=${onSubmit} class="form form-login">
                                ${step == 'Login' ? html`<fieldset class="fieldset login" data-hasrequired="* Required Fields">
                                    <div class="field note">If you have an account, sign in with your email address.
                                    </div>
                                    <div class="field email required">
                                        <label class="label" for="email"><span>Email</span></label>
                                        <div class="control">
                                            <input onInput=${setEmail} name="login[username]" value=${email} autocomplete="off" id="email"  required
                                                type="email" class="input-text" title="Email"/>
                                        </div>
                                    </div>
                                    <div class="field password required">
                                        <label for="pass" class="label"><span>Password</span></label>
                                        <div class="control">
                                            <input onInput=${setPassword} value=${password} name="login[password]" type="password" autocomplete="off"   required
                                                class="input-text" id="pass" title="Password"/>
                                        </div>
                                    </div>
                                    <div class="actions-toolbar">
                                        <div class="primary">
                                            <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                                <span>Sign In</span></button>
                                        </div>
                                        <div class="secondary"><a class="action remind" href="/forgot-password"><span>Forgot Your Password?</span></a></div>
                                    </div>
                                </fieldset>`: (step == 'SelectCustomer' ? html`<fieldset class="fieldset login" data-hasrequired="* Required Fields">
                                <div class="field note">Your account is linked with multiple customers.<br/>Please select the customer you would like to login with.
                                </div>
                                <div class="field email">
                                    <label class="label" for="cus"><span>CUS</span></label>
                                    <div class="control">
                                        <select onInput=${setCus} name="login[cus]" value=${cus}>
                                            ${customers.map(it => html`<option value=${it.cus}>${it.name} ${it.cus}</option>`)}
                                        </select>
                                    </div>
                                </div>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                            <span>Sign In</span></button>
                                    </div>
                                    <div class="secondary">
                                        <a class="action remind" onclick=${() => { setStep('Login'); setCode(undefined); setCus(undefined); }}>
                                            <span>Cancel</span>
                                        </a>
                                    </div>
                                </div>
                            </fieldset>` : html`<fieldset class="fieldset login" data-hasrequired="* Required Fields">
                            <div class="field note">A code has been sent to your email.<br/>Please use that code to login
                            </div>
                            <div class="field email">
                                <label class="label" for="code"><span>Code</span></label>
                                <div class="control">
                                    <div class="control required">
                                            <input onInput=${setCode} name="login[username]" value=${code} autocomplete="off" id="code" required
                                                type="text" class="input-text" title="Code"/>
                                    </div>
                                </div>
                            </div>
                            <div class="actions-toolbar">
                                <div class="primary">
                                    <button type="submit" class="action login primary ${loading ? 'disabled' : ''}" name="send" id="send2">
                                        <span>Sign In</span></button>
                                </div>
                                <div class="secondary">
                                    <a class="action remind" onclick=${() => { setStep('Login'); setCode(undefined); setCus(undefined); }}>
                                        <span>Cancel</span>
                                    </a>
                                </div>
                            </div>
                        </fieldset>`)}
                            </form>
                        </div>
                    </div>

                    <div class="block aw-ca-creat-wrapper">
                        <div class="aw-block-new-company">
                            <div class="block-title">
                                <h2 id="block-new-customer-heading">Register with Doug Discovery</h2>
                            </div>
                            <div class="block-content" aria-labelledby="block-new-customer-heading">
                                <p>
                                    Please register here to manage your account and orders. </p>
                                <div class="actions-toolbar">
                                    <div class="primary">
                                        <a href="/register" class="action create primary">
                                            <span>Sign Up</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>`;
}

function HeaderSearch() {

    const { state, dispatch } = ctx();

    return html`              <div class="header-search">
    <div class="block block-search">
        <div class="block block-title"><strong>Search</strong></div>
        <div class="block block-content">
                <div class="field search">
                    <div class="control">
                        <label class="label sr-only" for="search">
                            <span>Search</span>
                        </label>                       
                        <input onInput=${(e: InputEvent) => { dispatch({ type: 'setSearchQuery', query: (e.target as HTMLInputElement).value }) }} value=${state.search.q} id="search"   type="text" name="q" placeholder="Search entire store here..."
                            class="input-text" maxlength="128" role="combobox" aria-haspopup="false"
                            aria-autocomplete="both" autocomplete="off" aria-expanded="false" />
                        <div id="search_autocomplete" class="search-autocomplete"></div>
                        <div class="nested">
                            <a class="action advanced"
                                href="/advanced"
                                data-action="advanced-search">
                                Advanced Search </a>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <button onclick=${() => dispatch({ type: 'search' })} type="submit" title="Search" class="action search" aria-label="Search">
                        <span>Search</span>
                    </button>
                </div>
            <ul class="search-links">
                <li><${Link} activeClassName="current" href=${state.search.q.length ? '/catalog' : '/'}>Product Search<//></li>
                <li><${Link} activeClassName="current" href="/advanced">Concept Search<//></li>
                <li><${Link} activeClassName="current" href="/sketcher">Sketcher Search<//></li>                
            </ul>
        </div>
    </div>
</div>`;
}
interface ConceptBlock {
    name: string;
    code: string;
    count: number;
}



export function usePagination(total: number, initPage: number, initPageSize: number): [number, number, number[], (val: number) => void, (val: number) => void] {


    const calculatePages = (page: number, totalPages: number) => {
        const pages = [page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4].filter(p => p > 0 && p <= totalPages);
        if (pages.length > 5) pages.length = 5;
        return pages;
    };

    const [pageSize, setPageSize] = useState(initPageSize);
    const [page, setPage] = useState(initPage);

    const safeSetPage = (newPage: number) => {
        console.log('newPage', newPage);
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > Math.ceil((total) / pageSize)) {
            newPage = Math.ceil((total) / pageSize);
        }
        setPage(newPage);
        console.log('setpages', newPage, Math.ceil((total) / pageSize), 'PAGE', newPage)
        return [newPage, pageSize];
    }


    return [
        page,
        pageSize,
        calculatePages(page, Math.ceil(total / pageSize)),
        safeSetPage,
        (pageSize: number) => {
            setPageSize(pageSize);
            return [safeSetPage(1)[0], pageSize];
        },
    ]
}


function ConceptSearch() {

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [selelectedConcepts, setSelectedConcepts] = useState([] as ConceptBlock[]);

    const totalPages = () => {
        return Math.ceil(conceptBlocks.length / pageSize);
    };
    const onPageSizeSelect = (e: InputEvent) => {
        setPageSize(parseInt((e.target as HTMLSelectElement).value));
        setPage(1);
    }

    const getPages = (page: number, totalPages: number) => {
        const pages = [page - 2, page - 1, page, page + 1, page + 2, page + 3, page + 4].filter(p => p > 0 && p <= totalPages);
        if (pages.length > 5) pages.length = 5;
        return pages;
    };

    const getConceptBlockPage = () => {
        return conceptBlocks.slice((page - 1) * pageSize, page * pageSize);
    };


    const onPreviousClick = () => {
        if (page > 1) setPage(page - 1);
    };

    const onNextClick = () => {
        if (page < totalPages()) setPage(page + 1);
    };

    const isConceptBlockSelected = (conceptBlock: ConceptBlock) => {
        return selelectedConcepts.find(c => c.code === conceptBlock.code) !== undefined;
    }

    const toggleConceptBlock = (conceptBlock: ConceptBlock) => {
        if (isConceptBlockSelected(conceptBlock)) {
            setSelectedConcepts(selelectedConcepts.filter(c => c.code !== conceptBlock.code));
        } else {
            setSelectedConcepts([...selelectedConcepts, conceptBlock]);
        }
    }

    return html`<main id="maincontent" class="page-main"><div id="contentarea"></div>
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">Concept Search</span>    </h1>
        </div>
    <div class="page messages"><div data-placeholder="messages"></div>
    <div data-bind="scope: 'messages'">
        <!-- ko if: cookieMessages && cookieMessages.length > 0 --><!-- /ko -->
    
        <!-- ko if: messages().messages && messages().messages.length > 0 --><!-- /ko -->
    </div>
    
    </div><div class="columns"><div class="column main">
        <input name="form_key" type="hidden" value="i8hLt8ukPFs9dSGI"/>
        <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;">
        <!-- ko template: getTemplate() -->
    
    
    <!-- /ko -->
        
    </div>
    
    
    
    
    <div id="conceptSearch" class="conceptSearch">
        <div class="conceptSearch-wrapper">
            <div id="conceptSearch-table_wrapper" class="dataTables_wrapper no-footer">
                <table id="conceptSearch-table" class="conceptSearch-table dataTable no-footer" style="width: 100%" role="grid">
                    <thead>
                        <tr role="row">
                            <th class="sorting_disabled" rowspan="1" colspan="1" aria-label="Select"><span>Select</span></th>
                            <th class="sorting_desc" tabindex="0" aria-controls="conceptSearch-table" rowspan="1" colspan="1" aria-label="Concept Group: activate to sort column ascending" aria-sort="descending"><span>Concept Group</span></th>
                            <th class="sorting" tabindex="0" aria-controls="conceptSearch-table" rowspan="1" colspan="1" aria-label="Hits: activate to sort column ascending"><span>Hits</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        ${getConceptBlockPage().map(row => html`<tr role="row" class="odd">
                            <td><label class="custom-checkbox">
                                <input onClick=${() => toggleConceptBlock(row)} checked=${isConceptBlockSelected(row)} type="checkbox" class="conceptSearch-row-check" name="codes[]"/>
                                <div class="checkbox"></div></label>
                            </td>
                            <td class="sorting_1">${row.name}</td><td class="">${row.count}</td>
                        </tr>`)}
                </tbody>
            </table>
            <div class="conceptSearch-paging-container">
                <div class="dataTables_length" id="conceptSearch-table_length">
                    <label>Show 
                        <select value=${pageSize} onChange=${onPageSizeSelect} name="conceptSearch-table_length" aria-controls="conceptSearch-table" class="">
                            <option value="10" >10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="1000">All</option>
                        </select> entries</label>
                    </div>
                    <div class="dataTables_paginate paging_simple_numbers_no_ellipses" id="conceptSearch-table_paginate">
                        <a onClick=${onPreviousClick} class="paginate_button previous disabled" aria-controls="conceptSearch-table" data-dt-idx="0" tabindex="-1" id="conceptSearch-table_previous">
                            <span class="text">Previous</span>
                        </a>
                        <span>
                            ${getPages(page, totalPages()).map(p => html`<a onClick=${() => setPage(p)} class="paginate_button ${page == p ? 'current' : ''}" aria-controls="conceptSearch-table" data-dt-idx="1" tabindex="0">${p}</a>`)}
                        </span>
                        <a onClick=${onNextClick} class="paginate_button next" aria-controls="conceptSearch-table" data-dt-idx="6" tabindex="0" id="conceptSearch-table_next">
                            <span class="text">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div id="conceptSearch-selected" class="conceptSearch-selected">
                <ul>
                    ${selelectedConcepts.map(it => html`<li data-concept-code="${it.code}"><button class="conceptSearch-selected-remove">
                        <span class="text">Deselect</span></button><span>${it.name}</span>
                    </li>`)}
                </ul>
            </div>
        </div>
        <div class="conceptSearch-buttons">
            <button id="conceptSearch-search" class="conceptSearch-search action primary" disabled=${selelectedConcepts.length == 0} style="">Show ${selelectedConcepts.reduce((p, n) => p + n.count, 0)} Results</button>
        </div>
    </div>
    </div></div></main>`;
}

function Main() {


    const { state, dispatch } = ctx();
    const [validated, setValidated] = useState(false);

    const onProductSearchClick = (ev: Event) => {
        const isValid = state.search.q.length > 1;
        setValidated(true);
        if (!isValid) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.stopImmediatePropagation();
            return false;
        }
    }

    const onSearchSubmit = (ev: Event) => {

        ev.preventDefault();
        window.location.href = '/catalog?q=' + state.search.q;
    }

    useEffect(() => {
        dispatch({ type: 'setSearchQuery', query: '' });
    }, []);

    return html`<main id="maincontent" class="page-main">
            <div id="contentarea"></div>
            <div class="page messages">
                <div data-placeholder="messages"></div>
                <div data-bind="scope: 'messages'">
                    <!-- ko if: cookieMessages && cookieMessages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: cookieMessages, as: 'message' }" class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->

                    <!-- ko if: messages().messages && messages().messages.length > 0 -->
                    <div role="alert" data-bind="foreach: { data: messages().messages, as: 'message' }"
                        class="messages">
                        <div data-bind="attr: {
            class: 'message-' + message.type + ' ' + message.type + ' message',
            'data-ui-id': 'message-' + message.type
        }">
                            <div data-bind="html: $parent.prepareMessageForHtml(message.text)"></div>
                        </div>
                    </div>
                    <!-- /ko -->
                </div>
            </div>
            <div class="columns">
                <div class="column main">
                    <div class="homepage-container">
                        <div class="homepage-logo-container">
                            <img src="${require('/assets/images/d.png')}" alt="Doug Discovery" class="homepage-logo logo1" />
                            <img src="${require('/assets/images/fluologo.png')}" alt="Doug Discovery" class="homepage-logo logo2" />
                        </div>
                        <div class="homepage-search">
                            <form class="product-search" id="product_search_form" onSubmit=${onSearchSubmit}>

                                <label class="label sr-only" for="product_search_input" data-role="minisearch-label">
                                    <span>Product Search...</span>
                                </label>

                                <div class="product-search-input-wrapper">
                                   
                                    <input id="product_search_input" onInput=${(e: InputEvent) => dispatch({
        type: 'setSearchQuery', query: (e.target as HTMLInputElement).value
    })} value=${state.search.q} type="text" name="q" placeholder="Search by Name, CAS, SMILES, IUPAC etc."
                                        class="input-text" maxlength="128" minlength="3" role="combobox"
                                        aria-haspopup="false" aria-autocomplete="both" autocomplete="off" />
                                    <div id="product_search_autocomplete" class="search-autocomplete"></div>
                                    ${validated ? html`<div class="warning">Please input at least 2 characters</div>` : html``}
                                    <button type="submit" title="Search" class="action search">
                                        <span class="text">Search</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="homepage-search-buttons">
                        
                            <a onclick=${onProductSearchClick} href="/catalog?q=${encodeURIComponent(state.search.q)}" class="home-search-button-primary">
                                Product Search
                            </a>
                            <a href="/advanced?q=${encodeURIComponent(state.search.q)}" class="home-search-button">
                            Concept Search </a>
                            <a href="/sketcher" class="home-search-button">
                                Sketcher Search </a>                           
                        </div>
                    </div>
                </div>
            </div>
        </main>`;

}


function Sidebar() {



    const { state, dispatch } = ctx();

    const brands = Object.keys(state.products.reduce((p, n) => { p[n.brand] = true; return p; }, {} as { [key: string]: boolean }));

    const categories = Object.keys(state.products.reduce((p, n) => { p[n.category] = true; return p; }, {} as { [key: string]: boolean }));

    return html`<div class="sidebar-detached sidebar-left">
    <div class="sidebar">
        <!-- Ecommerce Sidebar Starts -->
        <div class="sidebar-shop">
            <div class="row">
                <div class="col-sm-12">
                    <h6 class="filter-heading d-none d-lg-block">Filters</h6>
                </div>
            </div>
            <div class="card pt-0">
                <div class="card-body">
                    <!-- Price Filter starts -
                    <div class="multi-range-price">
                        <h6 class="filter-title mt-0">Multi Range</h6>
                        <ul class="list-unstyled price-range" id="price-range">
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceAll" name="price-range" class="form-check-input"
                                        checked="" />
                                    <label class="form-check-label" for="priceAll">All</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange1" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange1">&lt;=$10</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange2" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange2">$10 - $100</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceARange3" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceARange3">$100 - $500</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-check">
                                    <input type="radio" id="priceRange4" name="price-range" class="form-check-input" />
                                    <label class="form-check-label" for="priceRange4">&gt;= $500</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Price Filter ends -->

                    <!-- Price Slider starts 
                    <div class="price-slider">
                        <h6 class="filter-title">Price Range</h6>
                        <div class="price-slider">
                            <div class="range-slider mt-2 noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"
                                id="price-slider">
                                <div class="noUi-base">
                                    <div class="noUi-connects">
                                        <div class="noUi-connect"
                                            style="transform: translate(29.2786%, 0px) scale(0.404122, 1);">
                                        </div>
                                    </div>
                                    <div class="noUi-origin" style="transform: translate(-707.214%, 0px); z-index: 5;">
                                        <div class="noUi-handle noUi-handle-lower" data-handle="0" tabindex="0"
                                            role="slider" aria-orientation="horizontal" aria-valuemin="51.0"
                                            aria-valuemax="3500.0" aria-valuenow="1500.0" aria-valuetext="1500">
                                            <div class="noUi-touch-area"></div>
                                            <div class="noUi-tooltip">1500</div>
                                        </div>
                                    </div>
                                    <div class="noUi-origin" style="transform: translate(-303.092%, 0px); z-index: 4;">
                                        <div class="noUi-handle noUi-handle-upper" data-handle="1" tabindex="0"
                                            role="slider" aria-orientation="horizontal" aria-valuemin="1500.0"
                                            aria-valuemax="5000.0" aria-valuenow="3500.0" aria-valuetext="3500">
                                            <div class="noUi-touch-area"></div>
                                            <div class="noUi-tooltip">3500</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Price Range ends -->
                    <div id="clear-filters">
                        <button onclick=${() => dispatch({ type: 'filter.clear' })} type="button" class="btn w-100 btn-primary waves-effect waves-float waves-light">Clear
                            All Filters</button>
                    </div>
                    <!-- Categories Starts -->
                    <div id="product-categories">
                        <h6 class="filter-title">Categories</h6>
                        <ul class="list-unstyled categories-list">
                        <li onclick=${(e: MouseEvent) => dispatch({ type: 'filter', filter: { ...state.filter, category: undefined } })}>
                                <div class="form-check"  >
                                    <input type="radio" id="category-all" name="category-filter" class="form-check-input"
                                        checked=${state.filter.category == undefined} />
                                    <label class="form-check-label" for="category1">All</label>
                                </div>
                            </li>
                            ${categories.map(category => html`<li onclick=${(e: MouseEvent) => dispatch({ type: 'filter', filter: { ...state.filter, category } })}>
                                <div class="form-check"  >
                                    <input type="radio" id="category-${category}" name="category-filter" class="form-check-input"
                                        checked=${state.filter.category.includes(category)} />
                                    <label class="form-check-label" for="category1">${category}</label>
                                </div>
                            </li>`)}
                        </ul>
                    </div>
                    <!-- Categories Ends -->

                    <!-- Brands starts -->
                    <div class="brands">
                        <h6 class="filter-title">Brands</h6>
                        <ul class="list-unstyled brand-list">
                            ${brands.map((brand, index) => html`
                            <li>
                                <div class="form-check">
                                    <input  onchange=${(e: InputEvent) => dispatch({ type: 'filter.in', key: 'brand', option: brand, value: (e.target as HTMLInputElement).checked })} type="checkbox" class="form-check-input" id="productBrand${index}" />
                                    <label class="form-check-label" for="productBrand${index}">${brand}</label>
                                </div>
                                <span></span>
                            </li>`)}
                        </ul>
                    </div>
                    <!-- Brand ends -->

                    <!-- Clear Filters Starts -->
                    
                    <!-- Clear Filters Ends -->
                </div>
            </div>
        </div>
        <!-- Ecommerce Sidebar Ends -->

    </div>
</div>`;
}


render(html`<${App} />`, document.body)


function AddNewAddress() {
    return html`<div class="column main">
    <div class="page-title-wrapper">
        <h1 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">Add New Address</span>
        </h1>
    </div>
    <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
    <div id="authenticationPopup" data-bind="scope:'authenticationPopup'" style="display: none;"></div>
    <form class="form-address-edit" 
        id="form-validate" enctype="multipart/form-data">
        <fieldset class="fieldset">
            <legend class="legend"><span>Contact Information</span></legend><br/>
            <input name="form_key" type="hidden" value="rZ83imfPSXICTyJZ" />
            <input type="hidden" name="success_url" value=""/>
            <input type="hidden" name="error_url" value=""/>

            <div class="field field-name-firstname required">
                <label class="label" for="firstname"><span>First Name</span></label>
                <div class="control">
                    <input type="text" id="firstname" name="firstname" value="Punchout" title="First&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>
            <div class="field field-name-lastname required">
                <label class="label" for="lastname"><span>Last Name</span></label>
                <div class="control">
                    <input type="text" id="lastname" name="lastname" value="Tester" title="Last&#x20;Name"
                        class="input-text required-entry" data-validate="{required:true}"/>
                </div>
            </div>


            <div class="field company ">
                <label for="company" class="label">
                    <span>
                        Organisation </span>
                </label>
                <div class="control">
                    <input type="text" name="company" id="company" value="" title="Organisation" class="input-text "/>
                </div>
            </div>


            <div class="field telephone required">
                <label for="telephone" class="label">
                    <span>
                        Phone Number </span>
                </label>
                <div class="control">
                    <input type="text" name="telephone" id="telephone" value="" title="Phone&#x20;Number"
                        class="input-text required-entry"/>
                </div>
            </div>


        </fieldset>
        <fieldset class="fieldset">
            <legend class="legend"><span>Address</span></legend><br/>
            <div class="field street required">
                <label for="street_1" class="label">
                    <span>Street Address</span>
                </label>
                <div class="control">
                    <input type="text" name="street[]" value="" title="Street Address" id="street_1"
                        class="input-text required-entry" />
                    <div class="nested">
                        <div class="field additional">
                            <label class="label" for="street_2">
                                <span>Street Address 2</span>
                            </label>
                            <div class="control">
                                <input type="text" name="street[]" value="" title="Street&#x20;Address&#x20;2"
                                    id="street_2" class="input-text "/>
                            </div>
                        </div>
                        <div class="field additional">
                            <label class="label" for="street_3">
                                <span>Street Address 3</span>
                            </label>
                            <div class="control">
                                <input type="text" name="street[]" value="" title="Street&#x20;Address&#x20;3"
                                    id="street_3" class="input-text "/>
                            </div>
                        </div>
                        <div class="field additional">
                            <label class="label" for="street_4">
                                <span>Street Address 4</span>
                            </label>
                            <div class="control">
                                <input type="text" name="street[]" value="" title="Street&#x20;Address&#x20;4"
                                    id="street_4" class="input-text "/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field city required">
                <label class="label" for="city"><span>City</span></label>
                <div class="control">
                    <input type="text" name="city" value="" title="City" class="input-text required-entry" id="city"/>
                </div>
            </div>
            <div class="field region required">
                <label class="label" for="region_id">
                    <span>State/Province</span>
                </label>
                <div class="control">
                    <select id="region_id" name="region_id" title="State/Province" class="validate-select region_id">
                        <option value="">Please select a region, state or province.</option>
                    </select>
                    <input type="text" id="region" name="region" value="" title="State/Province"
                        class="input-text validate-not-number-first " />
                </div>
            </div>
            <div class="field zip required">
                <label class="label" for="zip">
                    <span>Zip/Postal Code</span>
                </label>
                <div class="control">
                    <input type="text" name="postcode" value="" title="Zip/Postal Code" id="zip" class="input-text validate-zip-international "/>
                    <div role="alert" class="message warning" style="display:none">
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="field country required">
                <label class="label" for="country"><span>Country</span></label>
                <div class="control">
                    <${CountrySelect} name="country"/>
                </div>
            </div>

            <input type="hidden" name="default_billing" value="1" />

            <input type="hidden" name="default_shipping" value="1"/>
        </fieldset>
        <div class="actions-toolbar">
            <div class="primary">
                <button type="submit" class="action save primary" data-action="save-address" title="Save&#x20;Address">
                    <span>Save Address</span>
                </button>
            </div>
            <div class="secondary">
                <a class="action back" href="/customer/account/">
                    <span>Go back</span>
                </a>
            </div>
        </div>
    </form>
</div>`;
}

function CountrySelect({ name }: { name: string }) {
    return html`<select name=${name} class="" title="Country" required
    data-validate="{'validate-select':true}">
    <option value=""> </option>
    <option value="AF">Afghanistan</option>
    <option value="AX">Åland Islands</option>
    <option value="AL">Albania</option>
    <option value="DZ">Algeria</option>
    <option value="AS">American Samoa</option>
    <option value="AD">Andorra</option>
    <option value="AO">Angola</option>
    <option value="AI">Anguilla</option>
    <option value="AQ">Antarctica</option>
    <option value="AG">Antigua and Barbuda</option>
    <option value="AR">Argentina</option>
    <option value="AM">Armenia</option>
    <option value="AW">Aruba</option>
    <option value="AU">Australia</option>
    <option value="AT">Austria</option>
    <option value="AZ">Azerbaijan</option>
    <option value="BS">Bahamas</option>
    <option value="BH">Bahrain</option>
    <option value="BD">Bangladesh</option>
    <option value="BB">Barbados</option>
    <option value="BY">Belarus</option>
    <option value="BE">Belgium</option>
    <option value="BZ">Belize</option>
    <option value="BJ">Benin</option>
    <option value="BM">Bermuda</option>
    <option value="BT">Bhutan</option>
    <option value="BO">Bolivia</option>
    <option value="BA">Bosnia and Herzegovina</option>
    <option value="BW">Botswana</option>
    <option value="BV">Bouvet Island</option>
    <option value="BR">Brazil</option>
    <option value="IO">British Indian Ocean Territory</option>
    <option value="VG">British Virgin Islands</option>
    <option value="BN">Brunei</option>
    <option value="BG">Bulgaria</option>
    <option value="BF">Burkina Faso</option>
    <option value="BI">Burundi</option>
    <option value="KH">Cambodia</option>
    <option value="CM">Cameroon</option>
    <option value="CA">Canada</option>
    <option value="CV">Cape Verde</option>
    <option value="BQ">Caribbean Netherlands</option>
    <option value="KY">Cayman Islands</option>
    <option value="CF">Central African Republic</option>
    <option value="TD">Chad</option>
    <option value="CL">Chile</option>
    <option value="CN">China</option>
    <option value="CX">Christmas Island</option>
    <option value="CC">Cocos [Keeling] Islands</option>
    <option value="CO">Colombia</option>
    <option value="KM">Comoros</option>
    <option value="CG">Congo - Brazzaville</option>
    <option value="CD">Congo - Kinshasa</option>
    <option value="CK">Cook Islands</option>
    <option value="CR">Costa Rica</option>
    <option value="CI">Côte d’Ivoire</option>
    <option value="HR">Croatia</option>
    <option value="CU">Cuba</option>
    <option value="CW">Curaçao</option>
    <option value="CY">Cyprus</option>
    <option value="CZ">Czech Republic</option>
    <option value="DK">Denmark</option>
    <option value="DJ">Djibouti</option>
    <option value="DM">Dominica</option>
    <option value="DO">Dominican Republic</option>
    <option value="EC">Ecuador</option>
    <option value="EG">Egypt</option>
    <option value="SV">El Salvador</option>
    <option value="GQ">Equatorial Guinea</option>
    <option value="ER">Eritrea</option>
    <option value="EE">Estonia</option>
    <option value="ET">Ethiopia</option>
    <option value="FK">Falkland Islands</option>
    <option value="FO">Faroe Islands</option>
    <option value="FJ">Fiji</option>
    <option value="FI">Finland</option>
    <option value="FR">France</option>
    <option value="GF">French Guiana</option>
    <option value="PF">French Polynesia</option>
    <option value="TF">French Southern Territories</option>
    <option value="GA">Gabon</option>
    <option value="GM">Gambia</option>
    <option value="GE">Georgia</option>
    <option value="DE">Germany</option>
    <option value="GH">Ghana</option>
    <option value="GI">Gibraltar</option>
    <option value="GR">Greece</option>
    <option value="GL">Greenland</option>
    <option value="GD">Grenada</option>
    <option value="GP">Guadeloupe</option>
    <option value="GU">Guam</option>
    <option value="GT">Guatemala</option>
    <option value="GG">Guernsey</option>
    <option value="GN">Guinea</option>
    <option value="GW">Guinea-Bissau</option>
    <option value="GY">Guyana</option>
    <option value="HT">Haiti</option>
    <option value="HM">Heard Island and McDonald Islands</option>
    <option value="HN">Honduras</option>
    <option value="HK">Hong Kong SAR China</option>
    <option value="HU">Hungary</option>
    <option value="IS">Iceland</option>
    <option value="IN">India</option>
    <option value="ID">Indonesia</option>
    <option value="IR">Iran</option>
    <option value="IQ">Iraq</option>
    <option value="IE">Ireland</option>
    <option value="IM">Isle of Man</option>
    <option value="IL">Israel</option>
    <option value="IT">Italy</option>
    <option value="JM">Jamaica</option>
    <option value="JP">Japan</option>
    <option value="JE">Jersey</option>
    <option value="JO">Jordan</option>
    <option value="KZ">Kazakhstan</option>
    <option value="KE">Kenya</option>
    <option value="KI">Kiribati</option>
    <option value="KW">Kuwait</option>
    <option value="KG">Kyrgyzstan</option>
    <option value="LA">Laos</option>
    <option value="LV">Latvia</option>
    <option value="LB">Lebanon</option>
    <option value="LS">Lesotho</option>
    <option value="LR">Liberia</option>
    <option value="LY">Libya</option>
    <option value="LI">Liechtenstein</option>
    <option value="LT">Lithuania</option>
    <option value="LU">Luxembourg</option>
    <option value="MO">Macau SAR China</option>
    <option value="MK">Macedonia</option>
    <option value="MG">Madagascar</option>
    <option value="MW">Malawi</option>
    <option value="MY">Malaysia</option>
    <option value="MV">Maldives</option>
    <option value="ML">Mali</option>
    <option value="MT">Malta</option>
    <option value="MH">Marshall Islands</option>
    <option value="MQ">Martinique</option>
    <option value="MR">Mauritania</option>
    <option value="MU">Mauritius</option>
    <option value="YT">Mayotte</option>
    <option value="MX">Mexico</option>
    <option value="FM">Micronesia</option>
    <option value="MD">Moldova</option>
    <option value="MC">Monaco</option>
    <option value="MN">Mongolia</option>
    <option value="ME">Montenegro</option>
    <option value="MS">Montserrat</option>
    <option value="MA">Morocco</option>
    <option value="MZ">Mozambique</option>
    <option value="MM">Myanmar [Burma]</option>
    <option value="NA">Namibia</option>
    <option value="NR">Nauru</option>
    <option value="NP">Nepal</option>
    <option value="NL">Netherlands</option>
    <option value="AN">Netherlands Antilles</option>
    <option value="NC">New Caledonia</option>
    <option value="NZ">New Zealand</option>
    <option value="NI">Nicaragua</option>
    <option value="NE">Niger</option>
    <option value="NG">Nigeria</option>
    <option value="NU">Niue</option>
    <option value="NF">Norfolk Island</option>
    <option value="MP">Northern Mariana Islands</option>
    <option value="KP">North Korea</option>
    <option value="NO">Norway</option>
    <option value="OM">Oman</option>
    <option value="PK">Pakistan</option>
    <option value="PW">Palau</option>
    <option value="PS">Palestinian Territories</option>
    <option value="PA">Panama</option>
    <option value="PG">Papua New Guinea</option>
    <option value="PY">Paraguay</option>
    <option value="PE">Peru</option>
    <option value="PH">Philippines</option>
    <option value="PN">Pitcairn Islands</option>
    <option value="PL">Poland</option>
    <option value="PT">Portugal</option>
    <option value="QA">Qatar</option>
    <option value="RE">Réunion</option>
    <option value="RO">Romania</option>
    <option value="RU">Russia</option>
    <option value="RW">Rwanda</option>
    <option value="BL">Saint Barthélemy</option>
    <option value="SH">Saint Helena</option>
    <option value="KN">Saint Kitts and Nevis</option>
    <option value="LC">Saint Lucia</option>
    <option value="MF">Saint Martin</option>
    <option value="PM">Saint Pierre and Miquelon</option>
    <option value="VC">Saint Vincent and the Grenadines</option>
    <option value="WS">Samoa</option>
    <option value="SM">San Marino</option>
    <option value="ST">São Tomé and Príncipe</option>
    <option value="SA">Saudi Arabia</option>
    <option value="SN">Senegal</option>
    <option value="RS">Serbia</option>
    <option value="SC">Seychelles</option>
    <option value="SL">Sierra Leone</option>
    <option value="SG">Singapore</option>
    <option value="SX">Sint Maarten</option>
    <option value="SK">Slovakia</option>
    <option value="SI">Slovenia</option>
    <option value="SB">Solomon Islands</option>
    <option value="SO">Somalia</option>
    <option value="ZA">South Africa</option>
    <option value="GS">South Georgia and the South Sandwich Islands</option>
    <option value="KR">South Korea</option>
    <option value="ES">Spain</option>
    <option value="LK">Sri Lanka</option>
    <option value="SD">Sudan</option>
    <option value="SR">Suriname</option>
    <option value="SJ">Svalbard and Jan Mayen</option>
    <option value="SZ">Swaziland</option>
    <option value="SE">Sweden</option>
    <option value="CH">Switzerland</option>
    <option value="SY">Syria</option>
    <option value="TW">Taiwan</option>
    <option value="TJ">Tajikistan</option>
    <option value="TZ">Tanzania</option>
    <option value="TH">Thailand</option>
    <option value="TL">Timor-Leste</option>
    <option value="TG">Togo</option>
    <option value="TK">Tokelau</option>
    <option value="TO">Tonga</option>
    <option value="TT">Trinidad and Tobago</option>
    <option value="TN">Tunisia</option>
    <option value="TR">Turkey</option>
    <option value="TM">Turkmenistan</option>
    <option value="TC">Turks and Caicos Islands</option>
    <option value="TV">Tuvalu</option>
    <option value="UG">Uganda</option>
    <option value="UA">Ukraine</option>
    <option value="AE">United Arab Emirates</option>
    <option value="GB">United Kingdom</option>
    <option value="US">United States</option>
    <option value="UY">Uruguay</option>
    <option value="UM">U.S. Outlying Islands</option>
    <option value="VI">U.S. Virgin Islands</option>
    <option value="UZ">Uzbekistan</option>
    <option value="VU">Vanuatu</option>
    <option value="VA">Vatican City</option>
    <option value="VE">Venezuela</option>
    <option value="VN">Vietnam</option>
    <option value="WF">Wallis and Futuna</option>
    <option value="EH">Western Sahara</option>
    <option value="YE">Yemen</option>
    <option value="ZM">Zambia</option>
    <option value="ZW">Zimbabwe</option>
</select>`;
}


export function Loading() {
    return html`<div class="loading gray">
        <div></div>
        <div></div>
        </div>`;
}